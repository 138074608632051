import React from "react";
import AppRoutes from "./routes";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { theme } from "../src/config/theme";
import { AppProvider } from "./contexts/AppContext";
import { AuthProvider } from "./contexts/AuthContext";

window.Buffer = window.Buffer || require("buffer").Buffer;

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <CssBaseline />
          <AppRoutes />
        </AppProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
