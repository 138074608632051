import { createContext } from "react";
import { SnackbarProvider } from "./SnackbarProvider";
import { LoadingProvider } from "./LoadingProvider";

export const AppContext = createContext({});

export const AppProvider = ({ children }) => {
  return (
    <AppContext.Provider value={{}}>
      <LoadingProvider>
        <SnackbarProvider>
          {children}
        </SnackbarProvider>
      </LoadingProvider>
    </AppContext.Provider>
  );
};
