export const fetchDataWithLocalStorage = async (key, fetchFunction) => {
	let data = localStorage.getItem(key);

	if (data) {
		const parsedData = JSON.parse(data);
		return parsedData;
	} else {
		data = await fetchFunction();
		localStorage.setItem(key, JSON.stringify(data));
		return data;
	}
};