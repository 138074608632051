import { Box } from "../core";

export const BodyContainer = (props) => (
  <Box
    sx={{
      display: "flex",
      backgroundColor: "#FFFFFF",
      flexDirection: "column",
      height: "100%",
      flexGrow: 1,
      with: "100%",
    }}
    {...props}
  />
);
