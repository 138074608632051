import { TextField } from "../core";

export const ShortAnswer = () => {
  return (
    <TextField
      size="small"
      fullWidth
      placeholder="Resposta Curta"
      disabled
    />
  );
};
