import React, { useEffect, useState } from "react";
import { useLoading } from "../../contexts/LoadingProvider";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Text,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TextField,
  TablePagination,
  Chip,
} from "../../components/core";
import { BodyContainer } from "../../components/layout";
import { getCompanies } from "../../services/company";
import { getUsersByCompany } from "../../services/users";
import GoldIcon from "@mui/icons-material/EmojiEvents";
import SilverIcon from "@mui/icons-material/EmojiEvents";

export const UsersList = () => {
  const { openSnackbar } = useSnackbar();
  const { setLoading } = useLoading();
  const [companies, setCompanies] = useState([]);
  const [companySelected, setCompanySelected] = useState(0);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [roleSelected, setRoleSelected] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    async function initializeData() {
      try {
        setLoading(true);
        const companiesResponse = await getCompanies();
        setCompanies(companiesResponse.companies);
        const company_ids = companiesResponse.companies.map((c) => c.id);
        const usersResponse = await getUsersByCompany({
          company_ids,
        });
        setUsers(usersResponse.users);
      } catch (error) {
        openSnackbar("Erro ao carregar dados", "error");
      } finally {
        setLoading(false);
      }
    }

    initializeData();
  }, []);

  useEffect(() => {
    const searchLower = searchTerm.toLowerCase();
    const filtered = users.filter(
      (user) =>
        (user.name.toLowerCase().includes(searchLower) ||
          user.email.toLowerCase().includes(searchLower)) &&
        (companySelected === 0 ||
          user.companies.some((company) => company.id === companySelected)) &&
        (roleSelected === 0 || user.role === roleSelected)
    );
    setFilteredUsers(filtered);
  }, [users, searchTerm, companySelected, roleSelected]);

  const handleCompanyChange = (event) => {
    const selectedCompanyId = Number(event.target.value);
    setCompanySelected(selectedCompanyId);
  };

  const handleRowClick = (userId) => {
    navigate(`${userId}/edit`);
  };

  const handleRoleChange = (event) => {
    setRoleSelected(event.target.value);
  };

  return (
    <BodyContainer>
      <Box sx={{ display: "flex", alignItems: "center", my: 4 }}>
        <Text variant="h4" sx={{ fontWeight: "bold", width: "100%" }}>
          Usuários
        </Text>
        <Button
          component={Link}
          variant="contained"
          to="/users/new"
          sx={{ minWidth: 200 }}
        >
          Novo usuário
        </Button>
      </Box>
      <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
        <Paper
          elevation={2}
          sx={{ flexDirection: "row", display: "flex", gap: 2, padding: 2 }}
        >
          <FormControl>
            <InputLabel>Selecione a empresa</InputLabel>
            <Select
              sx={{ minWidth: 200 }}
              size="small"
              value={companySelected}
              label="Selecione a empresa"
              onChange={handleCompanyChange}
            >
              <MenuItem value={0}>Todas</MenuItem>
              {companies?.map((company) => (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel>Cargo</InputLabel>
            <Select
              size="small"
              value={roleSelected}
              label="Cargo"
              onChange={handleRoleChange}
              sx={{ minWidth: 120 }}
            >
              <MenuItem value={0}>Todos</MenuItem>
              <MenuItem value="silver">Silver</MenuItem>
              <MenuItem value="gold">Gold</MenuItem>
              <MenuItem value="operator">Operator</MenuItem>
            </Select>
          </FormControl>
          <TextField
            sx={{ flexGrow: 1 }}
            size="small"
            label="Pesquisar por nome ou email"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Paper>
        <Paper elevation={2}>
          <TablePagination
            component="div"
            count={filteredUsers?.length || 0}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(event) =>
              setRowsPerPage(parseInt(event.target.value, 10))
            }
            labelRowsPerPage="Usuários por página:"
            rowsPerPageOptions={[10, 15, 20]}
          />

          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={styles.TableHead}>
                  Cargo
                </TableCell>
                <TableCell sx={styles.TableHead}>Nome</TableCell>
                <TableCell sx={styles.TableHead}>Email</TableCell>
                <TableCell align="center" sx={styles.TableHead}>
                  Setores Atribuídos
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((user, index) => (
                  <TableRow
                    key={index}
                    onClick={() => handleRowClick(user.id)}
                    sx={styles.TableRow}
                  >
                    <TableCell align="center">
                      <Chip
                        label={user.role}
                        size="small"
                        sx={{
                          ...styles.chipStyle,
                          backgroundColor: roleColors[user.role]?.background,
                          color: roleColors[user.role]?.color,
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      {user.name ? user.name : "Nome não atribuído"}
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell align="center">
                      {user.sectors.map((sector) => (
                        <Text key={sector.id}>
                          {sector.name
                            ? sector.name
                            : "Sem setores atribuidos."}
                        </Text>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={filteredUsers?.length || 0}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(event) =>
              setRowsPerPage(parseInt(event.target.value, 10))
            }
            labelRowsPerPage="Usuários por página:"
            rowsPerPageOptions={[10, 15, 20]}
          />
        </Paper>
      </Box>
    </BodyContainer>
  );
};

const styles = {
  TableHead: {
    fontWeight: "bold",
    fontSize: "0.9rem",
  },
  TableRow: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.02)",
    },
    height: "70px",
    cursor: "pointer",
  },
  chipStyle: {
    width: "100px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    fontSize: "0.875rem",
    margin: "auto",
  },
};

const roleColors = {
  gold: { background: "#fff5cc", color: "#8d7000" },
  silver: { background: "#e0e0e0", color: "#000000" },
  operator: { background: "#e6f7ff", color: "#005691" },
};
