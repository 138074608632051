import { makeGeneralRequest } from "./makeRequest";

export const createSector = ({ sectors, companyId }) =>
  makeGeneralRequest("POST", `companies/${companyId}/sectors`, sectors);

export const getSectors = ({ companyId }) =>
  makeGeneralRequest("GET", `companies/${companyId}/sectors`);

export const getSectorsByCompany = ({ selectedCompanyIds }) =>
  makeGeneralRequest("POST", `/sectors_by_companies`, {
    company_ids: selectedCompanyIds,
  });

export const updateSector = (sector) => {
  const { companyId, sectorId, ...rest } = sector;
  return makeGeneralRequest(
    "PUT",
    `companies/${companyId}/sectors/${sectorId}`,
    rest
  );
};
