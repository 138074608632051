import React, { useEffect, useReducer, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { useParams } from "react-router-dom";
import { useLoading } from "../../contexts/LoadingProvider";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Text,
  Button,
  Select,
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  FormGroup,
  RadioGroup,
  Radio,
} from "../../components/core";
import { getCompanies } from "../../services/company";
import { getSectorsByCompany } from "../../services/sector";
import userReducer from "../../reducers/userReducer";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Container, IconButton, InputAdornment } from "@mui/material";
import { registerRequest } from "../../services/authentication";
import { getCompany } from "../../services/company";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const initialState = {
  formData: {
    email: "",
    role: "",
    company_ids: [],
    sector_ids: [],
    name: "",
    last_name: "",
    password: "",
    password_confirmation: "",
  },
  showPassword: false,
  companies: [],
  sectors: [],
  selectedSectors: [],
  selectedCompanies: [],
  selectedRole: "",
};

export const UserCreate = () => {
  const { openSnackbar } = useSnackbar();
  const { setLoading } = useLoading();
  const [companyId, setCompanyId] = useState([]);
  const { userId } = useParams();

  const [state, dispatch] = useReducer(userReducer, initialState);

  const Navigate = useNavigate();
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        setLoading(true);
        const response = await getCompanies();
        dispatch({ type: "SET_COMPANIES", payload: response.companies });
      } catch (error) {
        openSnackbar("Erro ao carregar as companias", "error");
      } finally {
        setLoading(false);
      }
    };
    fetchCompanies();
  }, []);

  useEffect(() => {
    const fetchSectors = async () => {
      try {
        const selectedCompanyIds = state.selectedCompanies.map(
          (company) => company.id
        );
        dispatch({ type: "CLEAR_SELECTED_SECTORS" });
        if (selectedCompanyIds.length) {
          const response = await getSectorsByCompany({ selectedCompanyIds });
          dispatch({
            type: "SET_SECTORS",
            payload: response.companies_sectors,
          });
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchSectors();
  }, [state.selectedCompanies]);

  useEffect(() => {
    if (!userId) return;
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const response = await fetchUserData(userId);
        dispatch({ type: "SET_FORM_DATA", payload: response });
      } catch (error) {
        console.error(error);
        openSnackbar("Erro ao carregar os dados do usuário", "error");
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchUserData();
    }
  }, [userId]);

  const handleChangeCompanies = (event) => {
    dispatch({ type: "SET_SELECTED_COMPANIES", payload: event.target.value });
    const selectedCompanies = event.target.value;
    dispatch({ type: "SET_SELECTED_COMPANIES", payload: selectedCompanies });
  };

  const handleSectorChange = (event, sectorId) => {
    if (event.target.checked) {
      dispatch({ type: "ADD_SELECTED_SECTOR", payload: sectorId });
    } else {
      dispatch({ type: "REMOVE_SELECTED_SECTOR", payload: sectorId });
    }
  };

  const handleChangeRole = (event) => {
    dispatch({ type: "SET_SELECTED_ROLE", payload: event.target.value });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    dispatch({ type: "SET_FORM_DATA", payload: { [name]: value } });
  };

  const handleSubmit = async () => {
    const user = {
      ...state.formData,
      company_ids: state.selectedCompanies.map(company => company.id),
      sector_ids: state.selectedSectors,
      role: state.selectedRole,
    };
    try {
      setLoading(true);
      const response = await registerRequest({ user });
      openSnackbar(response.data.message, "success");
      Navigate("/company");
    } catch (error) {
      openSnackbar("Erro ao criar o usuário", "error");
    } finally {
      setLoading(false);
      openSnackbar("Usuário criado com sucesso", "success");
    }
  };

  return (
    <Container>
      <Box sx={{ marginBlock: 4 }}>
        <Text sx={{ fontWeight: 600, fontSize: 24 }}>
          Criar um novo usuário
        </Text>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <FormControl sx={{ mt: 2, gap: 1 }}>
            <InputLabel id="role">Selecione o tipo de usuário</InputLabel>
            <Select
              label="Selecione o tipo de usuário"
              labelId="role"
              value={state.selectedRole}
              onChange={handleChangeRole}
            >
              <MenuItem value="gold">Administrador</MenuItem>
              <MenuItem value="silver">Gerente</MenuItem>
              <MenuItem value="operator">Operador</MenuItem>
            </Select>
            {state.selectedRole !== "gold" && (
              <>
                <Text
                  sx={{
                    fontSize: 20,
                    fontWeight: 500,
                    marginTop: 2,
                    marginBottom: 0,
                  }}
                >
                  Selecione a empresa
                </Text>
                <FormControl variant="filled" sx={{ m: 0, minWidth: 120 }}>
                  <InputLabel
                    variant="filled"
                    sx={{ fontSize: 12 }}
                    id="empresas"
                  >
                    Empresas
                  </InputLabel>
                  <Select
                    labelId="empresas-checkbox-label"
                    id="empresas"
                    multiple
                    value={state.selectedCompanies}
                    onChange={handleChangeCompanies}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => {
                      return selected.map((company) => company.name).join(", ");
                    }}
                    MenuProps={MenuProps}
                  >
                    {state.companies.map((company) => (
                      <MenuItem key={company.id} value={company}>
                        <Checkbox
                          checked={state.selectedCompanies.some(
                            (selectedCompany) =>
                              selectedCompany.id === company.id
                          )}
                        />
                        <ListItemText primary={company.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {Array.isArray(state.sectors) &&
                  state.sectors.map((companySector) => (
                    <div key={companySector.company.id}>
                      <Text>{companySector.company.name}</Text>
                      {companySector.sectors.map((sector) => (
                        <FormControl
                          variant="filled"
                          sx={{ m: 0, minWidth: 120 }}
                          key={sector.id}
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox />}
                              label={sector.name}
                              onChange={(event) =>
                                handleSectorChange(event, sector.id)
                              }
                            />
                          </FormGroup>
                        </FormControl>
                      ))}
                    </div>
                  ))}
              </>
            )}
          </FormControl>
          <TextField
            label="Nome"
            variant="filled"
            name="name"
            value={state.formData.name}
            onChange={handleChange}
          />
          <TextField
            label="Sobrenome"
            variant="filled"
            name="last_name"
            value={state.formData.last_name}
            onChange={handleChange}
          />
          <TextField
            label="Email"
            variant="filled"
            name="email"
            value={state.formData.email}
            onChange={handleChange}
          />

          <TextField
            label="Senha"
            name="password"
            type={state.showPassword ? "text" : "password"}
            value={state.formData.password}
            onChange={handleChange}
            variant="filled"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      dispatch({ type: "TOGGLE_PASSWORD_VISIBILITY" })
                    }
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirme a senha"
            name="password_confirmation"
            type={state.showPassword ? "text" : "password"}
            value={state.formData.password_confirmation}
            onChange={handleChange}
            variant="filled"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      dispatch({ type: "TOGGLE_PASSWORD_VISIBILITY" })
                    }
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button variant="primary" sx={styles.button} onClick={handleSubmit}>
            Criar usuário
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

const styles = {
  button: {
    marginBlock: 1,
    background: "#0e4292",
    color: "#FFF",
    fontWeight: 600,
    maxWidth: "200px",
  },
};
