import React from "react";
import { Box } from "../../components/core";
import { Menu, Footer } from "../../components/layout";

export const Layout = ({ showMenu = true, showFooter = true, children }) => {
  return (
    <Box sx={{ display: "flex" }}>
      {showMenu && <Menu />}
      <Box component="main" sx={{ flexGrow: 1, p: 3, pt: 8 }}>
        {children}
      </Box>
      {showFooter && <Footer />}
    </Box>
  );
};
