import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateForm } from "../../services/form";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import { Box, Button, Paper, Text, Switch, Grid } from "../core";

const ActiveSwitch = ({ isActive, onToggle }) => {
  const textColor = isActive ? "#0B3545" : "rgba(26, 25, 25, 0.40)";
  return (
    <Box sx={styles.SwitchBox}>
      <Text sx={{ color: textColor }}>{isActive ? "Desativar" : "Ativar"}</Text>
      <Switch checked={isActive} onChange={onToggle} />
    </Box>
  );
};

export const ItemCard = (props) => {
  const { title, createAt, tags, active, formId, companyId, type, responseId } =
    props;

  const { openSnackbar } = useSnackbar();
  const formattedDate = formatDate(createAt);
  const navigate = useNavigate();
  const [isCardActive, setIsCardActive] = useState(
    type === "formResponse" ? true : active
  );

  const handleSwitchChange = async (event) => {
    const newActiveState = event.target.checked;
    setIsCardActive(newActiveState);

    const response = await updateForm({
      formId,
      companyId,
      active: newActiveState,
    });

    if (response.name === "AxiosError") {
      openSnackbar("Erro ao atualizar formulário", "error");
      setIsCardActive(!newActiveState);
    } else {
      openSnackbar("Formulário atualizado com sucesso!");
    }
  };

  const textColor = isCardActive ? "#0B3545" : "rgba(26, 25, 25, 0.40)";

  //editar resposta
  const handleEditResponseClick = () => {
    navigate(`/company/${companyId}/form/${formId}/editResponse/${responseId}`);
  };

  //editar formulário
  const handleEditClick = () => {
    navigate(`/company/${companyId}/form/${formId}`);
  };
  // ver respostas
  const handleViewResponseClick = () => {
    navigate(`/company/${companyId}/responses/${formId}`);
  };
  // responder formulário
  const handleAnswerForm = () => {
    navigate(`/company/${companyId}/answerForm/${formId}`);
  };
  // ver uma resposta
  const handleViewResponseDetailsClick = () => {
    navigate(`/company/${companyId}/form/${formId}/showResponse/${responseId}`);
  };

  return (
    <Box sx={styles.Container}>
      <Paper sx={styles.Paper}>
        <Box sx={styles.containerTexts}>
          <Box>
            <Text sx={{ ...styles.title, color: textColor }}>{title}</Text>
            <Text sx={{ ...styles.createAt, color: textColor }}>
              <b>Data de criação</b>: {formattedDate}
            </Text>
            {props.createBy && (
              <Text sx={{ ...styles.createAt, color: textColor }}>
                <b>Enviado por</b>: {props.createBy}
              </Text>
            )}
            {props.content && (
              <Text sx={{ fontWeight: 600, marginTop: 2 }}>Resumo:</Text>
            )}
            {props.content &&
              Object.keys(props.content).map((key, i) => {
                if (i < 3) {
                  return (
                    <>
                      <Grid container spacing={1}>
                        <Grid item xs={12} key={i}>
                          <Text
                            variant="p"
                            sx={{ fontSize: 10, fontWeight: 600 }}
                          >
                            {key}:{" "}
                          </Text>
                          <Text variant="p" sx={{ fontSize: 10 }}>
                            {props.content[key]}
                          </Text>
                        </Grid>
                      </Grid>
                    </>
                  );
                }
                return null;
              })}
          </Box>

          <Text sx={{ ...styles.tags, color: textColor }}>
            {tags?.map((tag) => "#" + tag.name).join(", ")}
          </Text>
        </Box>
        <Box sx={styles.ActionsContent}>
          {type !== "formResponse" && isCardActive != null && (
            <ActiveSwitch
              isActive={isCardActive}
              onToggle={handleSwitchChange}
            />
          )}
          <Box sx={styles.ActionsButtons}>
            {type !== "formResponse" && (
              <Button
                sx={{ ...styles.ActionsButton, color: textColor }}
                onClick={handleAnswerForm}
              >
                Responder Formulário
              </Button>
            )}
            {type === "formResponse" ? (
              <Button
                sx={{ ...styles.ActionsButton, color: textColor }}
                onClick={handleViewResponseDetailsClick}
              >
                Ver detalhes
              </Button>
            ) : (
              <Button
                sx={{ ...styles.ActionsButton, color: textColor }}
                onClick={handleViewResponseClick}
              >
                Ver respostas
              </Button>
            )}
            {type === "formResponse" ? (
              <Button
                sx={{ ...styles.ActionsButton, color: textColor }}
                onClick={handleEditResponseClick}
              >
                Editar Resposta
              </Button>
            ) : (
              <Button
                sx={{ ...styles.ActionsButton, color: textColor }}
                onClick={handleEditClick}
              >
                Editar Formulário
              </Button>
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

const formatDate = (createAt) => {
  const dateString = createAt;
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Os meses começam em 0
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;

  return formattedDate;
};
const styles = {
  Container: {
    margin: 4,
  },
  Paper: {
    width: "100%",
    height: "auto",
    minHeight: 165,
    borderRadius: 4,
    paddingBlock: 1,
    paddingInline: 3,
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    display: "flex",
    alignItems: "stretch",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-end",
  },
  title: {
    color: "#1A1919",
    fontFamily: "Nunito",
    fontSize: 24,
    fontWeight: 700,
  },
  createAt: {
    color: "#5D5C5C",
    fontSize: 12,
    fontWeight: 400,
    fontFamily: "Nunito",
  },
  containerTexts: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
    fontFamily: "Nunito",

    mt: 1.4,
  },
  ActionsContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    fontFamily: "Nunito",

    gap: 2,
  },
  ActionsButtons: {
    display: "flex",
    gap: 4,
  },
  ActionsButton: {
    color: "#0B3545",
    fontWeight: 700,
  },
  SwitchBox: {
    display: "flex",
    alignItems: "center",
  },
  DeactiveMode: {
    color: "rgba(26, 25, 25, 0.40)",
  },
};
