import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Text,
  Checkbox,
  ListItemText,
  TextField,
  Button,
  FormHelperText,
} from "../../components/core";
import { BodyContainer } from "../../components/layout";
import { getCompanies } from "../../services/company";
import { useLoading } from "../../contexts/LoadingProvider";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import { getSectorsByCompany } from "../../services/sector";
import { createDashboard } from "../../services/dashboards";
import { useNavigate } from "react-router-dom";
export default function CreateDash() {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();
  const { setLoading } = useLoading();
  const [companySelected, setCompanySelected] = useState([]);
  const [sectorSelected, setSectorSelected] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [url, setUrl] = useState("");
  const [name, setName] = useState("");
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    async function initializeData() {
      try {
        setLoading(true);
        const companiesResponse = await getCompanies();
        setCompanies(companiesResponse.companies);
      } catch (error) {
        openSnackbar("Erro ao carregar dados", "error");
      } finally {
        setLoading(false);
      }
    }

    initializeData();
  }, []);

  useEffect(() => {
    const fetchSectors = async () => {
      try {
        const selectedCompanyIds = companySelected.map((company) => company.id);

        if (selectedCompanyIds.length) {
          const response = await getSectorsByCompany({ selectedCompanyIds });
          const newSectors = response.companies_sectors;

          setSectors(newSectors);

          setSectorSelected((currentSelectedSectors) => {
            // Filtrar para manter apenas setores que estão na nova lista de setores
            return currentSelectedSectors.filter((selectedSector) =>
              newSectors.some((newSectorGroup) =>
                newSectorGroup.sectors.some(
                  (sector) => sector.id === selectedSector.id
                )
              )
            );
          });
        } else {
          setSectors([]);
          setSectorSelected([]);
        }
      } catch (error) {
        // console.error(error);
      }
    };

    fetchSectors();
  }, [companySelected]);

  const handleSectorChange = (event) => {
    setSectorSelected(event.target.value);
    setFormData({
      ...formData,
      sector_ids: event.target.value.map((sector) => sector.id),
    });
  };

  const handleChangeCompanies = (event) => {
    setCompanySelected(event.target.value);
    setFormData({
      ...formData,
      company_ids: event.target.value.map((company) => company.id),
    });
  };

  const handleTextFieldChangeName = (event) => {
    setFormData({
      ...formData,
      name: event.target.value,
    });
  };
  const handleTextFieldChangeLink = (event) => {
    setFormData({
      ...formData,
      url: event.target.value,
    });
  };

  const [formData, setFormData] = useState({
    name: name,
    url: url,
    company_ids: [],
    sector_ids: [],
  });

  const validateForm = () => {
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = "Nome do dashboard é obrigatório";
    }
    if (companySelected.length === 0) {
      errors.company = "Pelo menos uma empresa é obrigatória";
    }
    if (sectorSelected.length === 0) {
      errors.sector = "Pelo menos um setor é obrigatório";
    }
    // Validação para o campo de URL
    if (!formData.url.trim()) {
      errors.url = "O link do formulário é obrigatório";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      await createDashboard({ formData });
      openSnackbar("Resposta enviada com sucesso!");
      navigate(`/dashboards`);
    } catch (error) {
      openSnackbar("Ocorreu um erro ao enviar as informações.", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <BodyContainer>
      <Box sx={{ mt: 4 }}>
        <Text variant="h1">Adicionar Dashboard</Text>
      </Box>
      <Box
        sx={{
          marginBlock: 3,
          gap: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextField
          required
          error={!!validationErrors.name}
          helperText={validationErrors.name}
          sx={styles.formField}
          label="Nome do dashboard"
          variant="outlined"
          value={formData.name}
          onChange={handleTextFieldChangeName}
        />
        <FormControl
          required
          sx={styles.formField}
          error={!!validationErrors.company}
        >
          <InputLabel>Selecione as empresas</InputLabel>
          <Select
            label="Selecione as empresas"
            multiple
            value={companySelected}
            onChange={handleChangeCompanies}
            renderValue={(selected) => {
              return selected.map((company) => company.name).join(", ");
            }}
            MenuProps={MenuProps}
          >
            {companies.map((company) => (
              <MenuItem key={company.id} value={company}>
                <Checkbox
                  checked={companySelected.some(
                    (selectedCompany) => selectedCompany.id === company.id
                  )}
                />
                <ListItemText primary={company.name} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{validationErrors.company}</FormHelperText>
        </FormControl>
        <FormControl
          required
          sx={styles.formField}
          error={!!validationErrors.company}
        >
          <InputLabel>Selecione os setores</InputLabel>
          <Select
            multiple
            value={sectorSelected}
            onChange={handleSectorChange}
            label="Selecione os setores"
            MenuProps={MenuProps}
            renderValue={(selected) => (
              <div>
                {selected.map((sector) => (
                  <span key={sector.id}>{sector.name}, </span>
                ))}
              </div>
            )}
          >
            {sectors &&
              sectors.map((item) =>
                item.sectors.map((sector) => {
                  return (
                    <MenuItem key={sector.id} value={sector}>
                      <Checkbox
                        checked={sectorSelected.some(
                          (selectedSector) => selectedSector.id === sector.id
                        )}
                      />
                      <ListItemText primary={sector.name} />
                    </MenuItem>
                  );
                })
              )}
          </Select>
          <FormHelperText>{validationErrors.sector}</FormHelperText>
        </FormControl>
        <TextField
          required
          error={!!validationErrors.url}
          helperText={validationErrors.url}
          sx={styles.formField}
          label="Insira o link do formulário"
          variant="outlined"
          value={formData.url}
          onChange={handleTextFieldChangeLink}
        />

        <Button onClick={handleSubmit} sx={styles.button} variant="contained">
          Salvar
        </Button>
      </Box>
    </BodyContainer>
  );
}

const styles = {
  button: {
    mt: 1,
    maxWidth: "200px",
  },
  formField: {
    maxWidth: 600,
  },
};
