import { useState } from "react";
import { format } from "date-fns";
import {
  Text,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
} from "../core";
import { TextareaAutosize } from "@mui/material";
import { Data } from "../fields/Data";

export const RenderField = ({ field, handleFieldChange, answers }) => {
  const states = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];
  const [ufSelected, setUfSelected] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);

  const handleChangeUf = (e) => {
    setUfSelected(e.target.value);
    const fieldLabel = "estado";
    const value = e.target.value;
    handleFieldChange(fieldLabel, value);
  };

  const handleRadioChange = (event, fieldTitle, value) => {
    setSelectedValue(event.target.value);
    const fieldLabel = event.target.name;
    handleFieldChange(fieldLabel, event.target.value);
  };

  const handleChange = (label, fieldTitle, value) => {
    const fieldLabel = label || fieldTitle;
    handleFieldChange(fieldLabel, value);
  };

  const handleChangeDate = (value) => {
    const fieldLabel = "data";
    const data = format(value.$d, "dd/MM/yyyy");
    handleFieldChange(fieldLabel, data);
    setSelectedDate(data);
  };

  const handleChangeCheckbox = (label, fieldTitle, option, event) => {
    const fieldLabel = label || fieldTitle;
    const isChecked = event.target.checked;

    let updatedValues = answers[fieldLabel] ? [...answers[fieldLabel]] : [];

    if (isChecked) {
      updatedValues.push(option);
    } else {
      updatedValues = updatedValues.filter((val) => val !== option);
    }

    handleFieldChange(fieldLabel, updatedValues);
  };

  switch (field.field_type) {
    case "multichoice":
      return (
        <Box sx={styles.containerFields}>
          <Text sx={{ fontSize: 18, fontWeight: 600 }}>
            {field.label ? field.label : field.title}
          </Text>

          {field.options.map((option, index) => {
            return (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    sx={{ marginInline: 2 }}
                    value={option}
                    onChange={(event) =>
                      handleChangeCheckbox(
                        field.label,
                        field.title,
                        option,
                        event
                      )
                    }
                  />
                }
                label={option}
              />
            );
          })}
        </Box>
      );
    case "short_answer":
      return (
        <Box sx={styles.containerFields}>
          <Text sx={{ fontSize: 18, fontWeight: 600 }}>
            {field.label ? field.label : field.title}
          </Text>
          <TextField
            type="text"
            key={field.id}
            label={field.title}
            variant="outlined"
            sx={styles.input}
            onChange={(e) =>
              handleChange(field.label, field.title, e.target.value)
            }
          />
        </Box>
      );
    case "radio":
      return (
        <Box sx={styles.containerFields}>
          <Text sx={{ fontSize: 18, fontWeight: 600 }}>
            {field.label ? field.label : field.title}
          </Text>
          <FormControl component="fieldset">
            <RadioGroup
              name={field.title}
              value={selectedValue}
              onChange={handleRadioChange}
            >
              {field.options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      );
    case "paragraph":
      return (
        <Box sx={styles.containerFields}>
          <Text sx={{ fontSize: 18, fontWeight: 600 }}>
            {field.label ? field.label : field.title}
          </Text>
          <TextareaAutosize
            onChange={(e) =>
              handleChange(field.label, field.title, e.target.value)
            }
            minRows={5}
            sx={{ borderColor: "#CCC" }}
          />
        </Box>
      );
    case "uf":
      return (
        <Box sx={styles.containerFields}>
          <Text sx={{ fontSize: 18, fontWeight: 600 }}>
            Selecione um estado
          </Text>
          <FormControl fullWidth sx={styles.input}>
            <InputLabel id="uf">Estado</InputLabel>
            <Select
              labelId="uf"
              value={ufSelected}
              label="Estado"
              onChange={handleChangeUf}
            >
              {states?.map((state) => (
                <MenuItem value={state}>{state}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      );
    case "date":
      return (
        <Box sx={styles.containerFields}>
          <Text sx={{ fontSize: 18, fontWeight: 600 }}>
            {field.label ? field.label : field.title}
          </Text>
          <Data value={selectedDate} handleChangeDate={handleChangeDate} />
        </Box>
      );
    default:
      return null;
  }
};

const styles = {
  button: {
    marginBlock: 1,
    background: "#0e4292",
    color: "#FFF",
    fontWeight: 600,
    maxWidth: "200px",
  },
  input: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",

    width: "100%",
    justifyContent: "center",
    marginBlock: 3,
    marginInline: 4,
  },
  containerFields: {
    display: "flex",
    width: 600,
    flexDirection: "column",
    marginBlock: 1,
  },
};
