import { useState } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ptBR from "dayjs/locale/pt-br";

export const Data = ({ handleChangeDate }) => {
  let value = dayjs("");
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      locale={ptBR}
      adapterLocale={"pt-BR"}
    >
      <DatePicker
        format="DD/MM/YYYY"
        value={value}
        onChange={handleChangeDate}
      />
    </LocalizationProvider>
  );
};
