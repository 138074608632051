export default function userReducer(state, action) {
  switch (action.type) {
    case "SET_FORM_DATA":
      return { ...state, formData: { ...state.formData, ...action.payload } };
    case "SET_COMPANIES":
      return { ...state, companies: action.payload };
    case "SET_SECTORS":
      return { ...state, sectors: action.payload };
    case "SET_SELECTED_SECTORS":
      return { ...state, selectedSectors: action.payload };
    case "SET_SELECTED_COMPANIES":
      return { ...state, selectedCompanies: action.payload };
    case "SET_SELECTED_ROLE":
      return { ...state, selectedRole: action.payload };
    case "TOGGLE_PASSWORD_VISIBILITY":
      return { ...state, showPassword: !state.showPassword };
    case "CLEAR_SELECTED_SECTORS":
      return { ...state, selectedSectors: [] };
    case "ADD_SELECTED_SECTOR":
      return {
        ...state,
        selectedSectors: [...state.selectedSectors, action.payload],
      };
    case "REMOVE_SELECTED_SECTOR":
      return {
        ...state,
        selectedSectors: state.selectedSectors.filter(
          (sector) => sector.id !== action.payload
        ),
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}
