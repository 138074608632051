import { Box, FormControlLabel, IconButton, Radio, Checkbox } from "../core";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";

export const OptionItem = ({
  option,
  fieldName,
  onEdit,
  onRemove,
  controlType,
  value,
}) => (
  <Box display="flex" alignItems="center">
    <FormControlLabel
      control={
        controlType === "radio" ? (
          <Radio name={fieldName} value={value} />
        ) : (
          <Checkbox />
        )
      }
      label={
        <Box
          component="div"
          sx={{
            maxWidth: 200,
            minWidth: 200,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {option}
        </Box>
      }
    />
    <Box sx={{ ml: 2 }}>
      <IconButton
        aria-label="editar"
        sx={{ "&:hover": { color: "#0e4292" } }}
        onClick={() => onEdit(fieldName, option)}
      >
        <EditIcon />
      </IconButton>
      <IconButton
        aria-label="remover"
        sx={{ "&:hover": { color: "red" } }}
        onClick={() => onRemove(fieldName, option)}
      >
        <ClearIcon />
      </IconButton>
    </Box>
  </Box>
);
