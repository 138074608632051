import { useEffect, useState } from "react";
import { useLoading } from "../../contexts/LoadingProvider";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import { useParams } from "react-router-dom";
import { BodyContainer } from "../../components/layout";
import { Box, Text } from "../../components/core";
import { ItemCard } from "../../components/patterns/itemCard";
import { getResponses } from "../../services/answer";

export const ResponseList = () => {
  const { openSnackbar } = useSnackbar();
  const { setLoading } = useLoading();
  const [page, setPage] = useState(1);
  const [forms, setForms] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const { companyId, formId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getResponses({ companyId, formId });
        setForms(response.form_submissions);
        setTotalPages(response.total_pages);
      } catch (error) {
        openSnackbar("Erro ao carregar formulários", "error");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [formId, companyId]);

  return (
    <BodyContainer>
      {forms && forms.length > 0 ? (
        <Box>
          <Text variant="h4" sx={{ margin: 4, fontWeight: "bold" }}>
            Respostas do Formulário {forms[0]?.title}
          </Text>
          <Box sx={{ maxWidth: 1000 }}>
            {forms.map((form, index) => (
              <ItemCard
                key={index}
                title={form.title}
                createAt={form.created_at}
                active={form.active}
                formId={formId}
                companyId={companyId}
                type={"formResponse"}
                responseId={form.id}
                createBy={form.user.email}
                content={form.content}
              />
            ))}
          </Box>
        </Box>
      ) : (
        <Text
          variant="h4"
          sx={{ marginTop: 10, marginLeft: 4, fontWeight: 400 }}
        >
          Nenhuma resposta encontrada
        </Text>
      )}
    </BodyContainer>
  );
};
