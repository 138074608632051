import React, { useState, useEffect } from "react";
import { useLoading } from "../../contexts/LoadingProvider";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import { useParams, useNavigate } from "react-router-dom";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ShortTextIcon from "@mui/icons-material/ShortText";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import NotesIcon from "@mui/icons-material/Notes";
import CreatableSelect from "react-select/creatable";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  Box,
  Text,
  Button,
  Select,
  TextField,
  MenuItem,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  ListItemText,
  Checkbox,
  FormControl,
  InputLabel,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
} from "../../components/core";
import { createForm, fetchForm, updateForm } from "../../services/form";
import { fetchFields, createField, updateFields } from "../../services/field";
import { useFieldManager } from "../../hooks/useFieldManager";
import { getSectors } from "../../services/sector";
import {
  ShortAnswerField,
  ParagraphField,
  UfField,
  MultiChoiceField,
  RadioField,
} from "../../components/fields";
import { uploadFileToS3 } from "../../utils/uploadToS3";
import { Data } from "../../components/fields/Data";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FormFieldWithFiles = ({ field, onDeleteFile, fieldIndex }) => {
  return (
    <FormControl
      variant="outlined"
      sx={{
        minWidth: 150,
        margin: "normal",
        position: "relative",
      }}
    >
      <Accordion
        sx={{
          position: "absolute",
          width: "100%",
          zIndex: 1000,
          ".MuiAccordionSummary-root": {
            minHeight: "37.56px",
            maxHeight: "37.56px",
          },
          ".MuiAccordionDetails-root": {
            px: 1,
            py: 0,
            maxHeight: "140px",
            overflow: "auto",
          },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Text sx={{ lineHeight: 1 }}>Arquivos</Text>
        </AccordionSummary>
        <Divider />
        <AccordionDetails sx={{ padding: "8px 16px" }}>
          <List>
            {field.files.map((file, fileIndex) => {
              const fileName = file.split("/").pop();
              return (
                <ListItem
                  key={fileIndex}
                  sx={{
                    px: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  secondaryAction={
                    <Tooltip title="Remover arquivo" placement="right">
                      <IconButton
                        edge="end"
                        onClick={() => onDeleteFile(fieldIndex, fileIndex)}
                      >
                        <DeleteIcon sx={{ "&:hover": { color: "red" } }} />
                      </IconButton>
                    </Tooltip>
                  }
                >
                  <Tooltip title={fileName} placement="right">
                    <ListItemText
                      primary={fileName}
                      sx={{
                        p: 0,
                        m: 0,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "70px",
                      }}
                    />
                  </Tooltip>
                </ListItem>
              );
            })}
          </List>
        </AccordionDetails>
      </Accordion>
    </FormControl>
  );
};

const FileUploadIconButton = ({ onFileChange }) => {
  const handleFileInputChange = (event) => {
    if (event.target.files.length > 0) {
      onFileChange(event.target.files[0]);
    }
  };

  return (
    <Tooltip title="Adicionar arquivo" placement="right-start">
      <IconButton
        variant="outlined"
        size="large"
        sx={{
          borderColor: "#bdbdbd",
          "&:hover": { borderColor: "#000", color: "#0e4292" },
        }}
      >
        <input
          type="file"
          onChange={handleFileInputChange}
          // hidden
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            opacity: 0,
            top: 0,
            left: 0,
            cursor: "pointer",
          }}
        />
        <CloudUploadIcon />
      </IconButton>
    </Tooltip>
  );
};

const createDefaultForm = () => ({
  title: "",
  tags: [],
  active: true,
  sector_id: "",
});

const createDefaultField = () => ({
  name: "",
  label: "",
  title: "Digite o Título da Pergunta",
  field_type: "short_answer",
  options: [],
  // hiden_unless: [],
  // optionInput: "",
  multiple: false,
  autocomplete: false,
  minimum: null,
  maximum: null,
  files: [],
});

export const FormEditor = () => {
  const {
    formContent = [],
    deletedFields,
    setFormContent,
    editField,
    editFieldType,
    moveField,
    addFieldOption,
    setTextField,
    deleteField,
  } = useFieldManager([createDefaultField()]);
  const { openSnackbar } = useSnackbar();
  const { setLoading } = useLoading();
  const { companyId, formId } = useParams();
  const navigate = useNavigate();
  const [form, setForm] = useState(createDefaultForm());
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [sectors, setSectors] = useState("");
  const [sectorSelected, setSectorSelected] = useState([]);
  const [editOptionData, setEditOptionData] = useState({
    fieldName: "",
    option: "",
  });

  const fieldTypes = {
    short_answer: { display: "Resposta Curta", Icon: ShortTextIcon },
    paragraph: { display: "Parágrafo", Icon: NotesIcon },
    multichoice: {
      display: "Multipla Escolha",
      Icon: CheckBoxOutlineBlankIcon,
    },
    radio: { display: "Escolha Única", Icon: RadioButtonCheckedIcon },
    uf: { display: "UF", Icon: ShortTextIcon },
    date: { display: "Data", Icon: CalendarMonthIcon },
  };

  useEffect(() => {
    let isMounted = true;
    if (formId && companyId) {
      const fetchData = async () => {
        setLoading(true);
        const formData = await fetchForm({ companyId, formId });
        if (formData.response?.status === 404) {
          navigate(`/forms`);
          openSnackbar("Formulário não encontrado", "error");
          return;
        }
        const { fields } = await fetchFields({ companyId, formId });
        if (isMounted) {
          const formWithCorrectTags = {
            ...formData.form,
            tags: formData.form.tags.map((tag) => ({ value: tag, label: tag })),
          };
          setForm(formWithCorrectTags);
          setFormContent(fields);
          setLoading(false);
        }
      };

      fetchData();
      return () => {
        isMounted = false;
      };
    } else {
      setForm(createDefaultForm());
      setFormContent([createDefaultField()]);
    }
  }, [formId, companyId]);

  useEffect(() => {
    const fetchData = async () => {
      const sectorsData = await getSectors({ companyId });
      setSectors(sectorsData.sectors);
    };
    fetchData();
  }, []);

  const handleFileUpload = (file, fieldIndex) => {
    uploadFileToS3(file)
      .then((file) => {
        setFormContent((prevContent) =>
          prevContent.map((field, index) =>
            index === fieldIndex
              ? { ...field, files: [...field.files, file.location] }
              : field
          )
        );
      })
      .catch((err) => {
        console.error("Error uploading file: ", err);
      });
  };

  const removeUpload = (fieldIndex, fileIndex) => {
    setFormContent((prevContent) =>
      prevContent.map((field, index) => {
        if (index === fieldIndex) {
          return {
            ...field,
            files: field.files.filter((_, idx) => idx !== fileIndex),
          };
        }
        return field;
      })
    );
  };

  const saveForm = async () => {
    setLoading(true);
    const tags = form.tags.map((tag) => tag.value.name);
    const payload = {
      ...form,
      tags,
      companyId,
      formId,
      deleted_field_ids: deletedFields,
    };

    let formResponse;

    if (formId) {
      await executeUpdates(payload);
      openSnackbar("Formulário atualizado com sucesso!", "success");
    } else {
      formResponse = await createForm({ form: payload, companyId });

      const { form: formResponseData } = formResponse;
      await createField({
        fields: formContent,
        companyId,
        formId: formResponseData.id,
      });

      if (formResponse)
        openSnackbar("Formulário criado com sucesso!", "success");
    }

    navigate(`/forms`);
    setLoading(false);
  };

  const executeUpdates = async (payload) => {
    let formResponse = await updateForm(payload);
    let data = { fields: formContent, deleted_field_ids: deletedFields };

    let fieldResponse = await updateFields({
      data,
      companyId,
      formId,
    });

    if (
      formResponse.name === "AxiosError" ||
      fieldResponse.name === "AxiosError"
    ) {
      openSnackbar("Erro ao salvar o formulário", "error");
      setLoading(false);
      return;
    }
  };

  const addField = () => {
    setFormContent([...formContent, createDefaultField()]);
  };

  const transformedTags = form?.tags.map((tag) => ({
    value: tag.value.id,
    label: tag.label.name,
  }));

  const handleTagChange = (newValue) => {
    const transformedBack = (newValue || []).map((tag) => ({
      value: { id: tag.value, name: tag.label },
      label: { id: tag.value, name: tag.label },
    }));

    setForm((prevForm) => ({
      ...prevForm,
      tags: transformedBack,
    }));
  };

  const openEditModal = (fieldIndex, optionIndex) => {
    const field = formContent[fieldIndex];
    const option = field.options[optionIndex];

    setEditOptionData({ fieldIndex, optionIndex, option });
    setEditModalOpen(true);
  };

  const handleEditOption = (newOption) => {
    setFormContent((prevContent) => {
      const newContent = [...prevContent];
      const fieldIndex = editOptionData.fieldIndex;
      const optionIndex = editOptionData.optionIndex;

      const field = newContent[fieldIndex];
      const options = [...field.options];

      options[optionIndex] = newOption;

      newContent[fieldIndex] = {
        ...field,
        options: options,
      };

      return newContent;
    });

    setEditModalOpen(false);
    setEditOptionData({});
  };

  const removeFieldOption = (fieldIndex, optionIndex) => {
    setFormContent((prevContent) =>
      prevContent.map((field, idx) => {
        if (idx === fieldIndex) {
          return {
            ...field,
            options: field.options.filter(
              (_, optIdx) => optIdx !== optionIndex
            ),
          };
        }
        return field;
      })
    );
  };

  const handleSectorChange = (event) => {
    const {
      target: { value },
    } = event;
    setSectorSelected(typeof value === "string" ? value.split(",") : value);
    setForm({ ...form, sector_id: value });
  };

  return (
    <>
      <Box sx={styles.ContainerForm}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text variant="h4" fontWeight="bold">
            {formId ? "Editar Formulário" : "Criar Formulário"}
          </Text>
        </Box>

        <TextField
          fullWidth
          placeholder="Formulário sem Título"
          value={form.title}
          onChange={(e) => setForm({ ...form, title: e.target.value })}
        />
        <Box sx={{ display: "flex", gap: 2 }}>
          <Box sx={{ width: "100%" }}>
            <CreatableSelect
              isMulti
              onChange={handleTagChange}
              options={transformedTags}
              value={transformedTags}
              placeholder="Adicione as Tags do Formulário"
              noOptionsMessage={() => "Nenhuma opção encontrada"}
              styles={{
                control: (base, state) => ({
                  ...base,
                  minHeight: "56px",
                  borderColor: state.isFocused ? "#0e4292" : base.borderColor,
                  boxShadow: state.isFocused
                    ? "0 0 0 1px #0e4292"
                    : base.boxShadow,
                  "&:hover": {
                    borderColor: state.isFocused ? "#0e4292" : base.borderColor,
                  },
                }),
              }}
            />
          </Box>
          <Box sx={{ width: "100px" }}>
            <Select
              fullWidth
              value={form.active}
              onChange={(e) => setForm({ ...form, active: e.target.value })}
            >
              <MenuItem value={true}>Ativo</MenuItem>
              <MenuItem value={false}>Inativo</MenuItem>
            </Select>
          </Box>
          <Box sx={{ width: "100%" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Setores</InputLabel>
              <Select
                label="Setores"
                fullWidth
                multiple
                value={sectorSelected}
                onChange={handleSectorChange}
                MenuProps={MenuProps}
                renderValue={(selected) => (
                  <div>
                    {selected.map((sector) => (
                      <span key={sector.id}>{sector.name}, </span>
                    ))}
                  </div>
                )}
              >
                {sectors.length ? (
                  sectors.map((sector) => (
                    <MenuItem key={sector.id} value={sector}>
                      <Checkbox
                        checked={sectorSelected.some(
                          (selectedSector) => selectedSector.id === sector.id
                        )}
                      />
                      <ListItemText primary={sector.name} />
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>
                    <ListItemText primary="Não há setores cadastrados" />
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box sx={styles.ContentForm}>
          <Divider sx={{ mb: 4 }} />
          {formContent?.map((field, index) => {
            return (
              <Box
                key={`Campo${index}`}
                sx={{
                  display: "flex",
                  gap: 1,
                  maxWidth: 1000,
                }}
              >
                <Box key={`field_${index}`} sx={styles.BoxForm}>
                  <Box sx={styles.FormFieldBox}>
                    <TextField
                      sx={{ maxWidth: 1000, width: "100%" }}
                      size="small"
                      value={field.title}
                      onChange={(e) => editField(index, e.target.value)}
                    />

                    <Box>
                      <Select
                        size="small"
                        value={field.field_type}
                        onChange={(e) => editFieldType(index, e.target.value)}
                        renderValue={(value) => {
                          const Icon = fieldTypes[value].Icon;
                          return (
                            <Box display="flex" alignItems="center">
                              {Icon && <Icon sx={{ mr: 1 }} />}
                              <span>{fieldTypes[value].display}</span>
                            </Box>
                          );
                        }}
                      >
                        {Object.entries(fieldTypes).map(
                          ([value, { display, Icon: FieldIcon }]) => (
                            <MenuItem value={value} key={value}>
                              {FieldIcon && <FieldIcon sx={{ mr: 1 }} />}
                              {display}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </Box>
                    {field.files.length > 0 && (
                      <FormFieldWithFiles
                        field={field}
                        fieldIndex={index}
                        onDeleteFile={removeUpload}
                      />
                    )}
                  </Box>

                  <Box sx={{ my: 4, width: "100%" }}>
                    {field.field_type === "short_answer" && (
                      <ShortAnswerField />
                    )}
                    {field.field_type === "paragraph" && <ParagraphField />}
                    {field.field_type === "date" && <Data />}
                    {field.field_type === "multichoice" && (
                      <MultiChoiceField
                        field={field}
                        index={index}
                        openEditModal={openEditModal}
                        removeFieldOption={removeFieldOption}
                        setTextField={setTextField}
                        addFieldOption={addFieldOption}
                      />
                    )}
                    {field.field_type === "radio" && (
                      <RadioField
                        field={field}
                        index={index}
                        openEditModal={openEditModal}
                        removeFieldOption={removeFieldOption}
                        setTextField={setTextField}
                        addFieldOption={addFieldOption}
                      />
                    )}
                    {field.field_type === "uf" && <UfField />}
                  </Box>
                </Box>
                <Box
                  sx={{
                    py: 1,
                    borderRadius: 3,
                    display: "flex",
                    boxShadow: 1,
                    height: "100%",
                    flexDirection: "column",
                    border: "1px solid #ddd",
                  }}
                >
                  <Tooltip title="Mover para cima" placement="right-start">
                    <IconButton
                      sx={{ "&:hover": { color: "#0e4292" } }}
                      aria-label="Mover para cima"
                      disabled={index === 0}
                      onClick={() => moveField(index, "up")}
                    >
                      <ArrowCircleUpIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Mover para baixo" placement="right-start">
                    <IconButton
                      sx={{ "&:hover": { color: "#0e4292" } }}
                      aria-label="Mover para baixo"
                      disabled={index === formContent.length - 1}
                      onClick={() => moveField(index, "down")}
                    >
                      <ArrowCircleDownIcon />
                    </IconButton>
                  </Tooltip>

                  <FileUploadIconButton
                    onFileChange={(file) => handleFileUpload(file, index)}
                    fieldIndex={index}
                  />
                  <Tooltip title="Remover campo" placement="right-start">
                    <IconButton
                      aria-label="Remover campo"
                      sx={{ "&:hover": { color: "red" } }}
                      onClick={() => deleteField(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            );
          })}
          <Box sx={styles.AddFieldButton}>
            <Button onClick={() => addField()} variant="contained" size="large">
              Novo Campo
            </Button>
            <Button variant="contained" onClick={() => saveForm()} size="large">
              Salvar formulário
            </Button>
          </Box>
        </Box>
      </Box>
      <Dialog open={editModalOpen} onClose={() => setEditModalOpen(false)}>
        <DialogTitle>Editar Opção</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Opção"
            value={editOptionData.option}
            onChange={(e) =>
              setEditOptionData({ ...editOptionData, option: e.target.value })
            }
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditModalOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => handleEditOption(editOptionData.option)}
            color="primary"
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const styles = {
  ContainerForm: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
    px: 4,
    py: 4,
    maxWidth: 1000,
  },
  ContentForm: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  BoxForm: {
    maxWidth: 880,
    width: "100%",
    borderRadius: 3,
    bgcolor: "white",
    display: "flex",
    boxShadow: 1,
    px: 4,
    flexDirection: "column",
    gap: 2,
    border: "1px solid #ddd",
  },
  FormFieldBox: {
    gap: 2,
    display: "flex",
    mt: 2,
  },
  AddFieldButton: {
    gap: 3,
    display: "flex",
    position: "fixed",
    bottom: "5%",
    right: 50,
  },
};
