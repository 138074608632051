import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLoading } from "../../contexts/LoadingProvider";
import { getResponseById } from "../../services/answer";
import { BodyContainer } from "../../components/layout";
import { Box, Text, Paper, Grid, Divider } from "../../components/core";

export const ShowResponse = () => {
  const { setLoading } = useLoading();
  const [form, setForm] = useState({});
  const { formId, companyId, responseId } = useParams();

  useEffect(() => {
    let isMounted = true;
    const fetchforms = async () => {
      setLoading(true);
      const response = await getResponseById({
        companyId,
        formId,
        responseId,
      });
      if (isMounted) {
        setForm(response.form_submission);
        setLoading(false);
      }
    };
    fetchforms();
    return () => {
      isMounted = false;
    };
  }, [formId, companyId, responseId]);

  return (
    <BodyContainer>
      <Paper
        sx={{ padding: "20px", margin: "20px", borderRadius: 6, p: 4 }}
        elevation={1}
      >
        <Text sx={{ fontSize: 24, fontWeight: 600 }} gutterBottom>
          {form.title}
        </Text>
        <Grid container spacing={3} sx={{ mb: 3 }}>
          {form.content &&
            typeof form.content === "object" &&
            !Array.isArray(form.content) &&
            Object.keys(form.content).map((item, i) => {
              const value = form.content[item];
              return (
                <Grid item xs={6} key={i}>
                  <Text variant="h3" sx={styles.titles}>
                    {item}:
                  </Text>
                  {Array.isArray(value) ? (
                    value.map((arrayItem, index) => {
                      return (
                        <Text variant="p" sx={styles.subTitles} key={index}>
                          {arrayItem},
                        </Text>
                      );
                    })
                  ) : (
                    <Text variant="p" sx={styles.subTitles}>
                      {value}
                    </Text>
                  )}
                </Grid>
              );
            })}
        </Grid>
        <Divider
          variant="middle"
          sx={{ background: "#DDD", my: 0, mx: 0, p: 0 }}
        />

        {form.content_updates &&
          Array.isArray(form.content_updates) &&
          form.content_updates.length > 0 && (
            <Grid container spacing={2}>
              <Grid item xs={6} sx={{ marginTop: 2 }}>
                <Text
                  variant="h4"
                  sx={([styles.titles], { fontSize: 20, fontWeight: 600 })}
                >
                  Última Atualização:
                </Text>
                <Grid item xs={6}>
                  {Object.entries(
                    form.content_updates[form.content_updates.length - 1]
                  ).map(([key, value], i) => (
                    <Box key={i} sx={{ my: 2 }}>
                      <Text variant="h3" sx={{ fontSize: 16, fontWeight: 500 }}>
                        {key}:
                      </Text>
                      <Text variant="p" sx={styles.subTitles}>
                        {Array.isArray(value) ? value.join(", ") : value}
                      </Text>
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )}
        <Divider
          variant="middle"
          sx={{ background: "#DDD", my: 0, mx: 0, p: 0 }}
        />
        <Text sx={[styles.creationDate, { marginTop: 2 }]}>
          <b>Resposta enviada</b>: {new Date(form.created_at).toLocaleString()}
        </Text>
        <Text sx={styles.creationDate}>
          <b>Ultima atualização</b>:{" "}
          {new Date(form.updated_at).toLocaleString()}
        </Text>
      </Paper>
    </BodyContainer>
  );
};

const styles = {
  titles: {
    fontSize: 18,
    fontWeight: 500,
    fontFamily: "Montserrat",
    color: "#AAA09",
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 400,
    fontFamily: "Montserrat",
    color: "#DDD",
  },

  creationDate: {
    fontSize: 14,
    color: "#7f7f7f",
    fontWeight: 300,
  },
};
