import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const apiV1 = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
  timeout: 10000,
  headers: {
    
    "Content-Type": "application/json",
    "Accept": "application/json"
  },
});

export const apiUsers = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json"
  },
});

apiV1.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authentication_token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiUsers.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authentication_token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
