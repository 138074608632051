import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Text,
  Button,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "../../components/core";
import { BodyContainer } from "../../components/layout";
import { createResponse } from "../../services/answer";
import { fetchFields } from "../../services/field";
import { useLoading } from "../../contexts/LoadingProvider";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import { useNavigate } from "react-router-dom";
import { RenderField } from "../../components/patterns/RenderField";

const Answer = () => {
  const { openSnackbar } = useSnackbar();
  const { setLoading } = useLoading();
  const { formId, companyId } = useParams();
  const [fields, setFields] = useState([]);
  const [answers, setAnswers] = useState({});
  const [selectedValue, setSelectedValue] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    // fetchFields({ companyId, formId }).then(setFields);
    const fetchData = async () => {
      try {
        setLoading(true);
        const { fields } = await fetchFields({ companyId, formId });
        setFields(fields);
      } catch (error) {
        openSnackbar("Erro ao carregar formulários", "error");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [companyId, formId]);

  const handleFieldChange = (fieldLabel, value) => {
    setAnswers({ ...answers, [fieldLabel]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);

    const formSubmission = {
      form_submission: {
        title: formId,
        content: answers,
      },
    };

    try {
      await createResponse({ formSubmission, companyId, formId });
      openSnackbar("Resposta enviada com sucesso!");
      navigate(`/forms`);
    } catch (error) {
      openSnackbar("Erro ao enviar a resposta.", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <BodyContainer>
      <Text
        variant="h4"
        sx={{ marginBlock: 2, marginInline: 4, fontWeight: "bold" }}
      >
        Responder formulário
      </Text>
      <Box sx={styles.container}>
        {fields.map((field) => (
          <Box key={field.id} sx={{ width: "100%" }}>
            <RenderField
              field={field}
              handleFieldChange={handleFieldChange}
              answers={answers}
            />
          </Box>
        ))}
        <Button onClick={handleSubmit} sx={styles.button}>
          Enviar Respostas
        </Button>
      </Box>
    </BodyContainer>
  );
};

const styles = {
  button: {
    marginBlock: 1,
    background: "#0e4292",
    color: "#FFF",
    fontWeight: 600,
    maxWidth: "200px",
  },
  input: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    width: "100%",
    justifyContent: "center",
    marginBlock: 2,
    marginInline: 4,
  },
  containerFields: {
    display: "flex",
    width: 600,
    flexDirection: "column",
  },
};
export default Answer;
