import React from "react";
import { Box, Text, Grid, Button } from "../../components/core";
import { useNavigate } from "react-router-dom";
import { useLoaderData } from "react-router-dom";

const Company = () => {
  const { companies } = useLoaderData();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        marginBlock: 4,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Text variant="h4" sx={{ fontWeight: 600 }}>
        Selecionar Empresa
      </Text>
      <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
        <Grid container spacing={3}>
          {companies.length && companies?.map(
            (company) =>
              company.active && (
                <Grid item xs={12} md={4} key={company.id} sx={{}}>
                  <Box
                    onClick={() => navigate(`/company/${company.id}/forms`)}
                    sx={{
                      height: 142,
                      borderRadius: 5,
                      border: "1px solid #E0E0E0",
                      backgroundColor: "#e7e7e7",
                      backgroundImage: `url(${company.thumbnail_url})`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      cursor: "pointer",
                      "&:hover": {
                        filter: "brightness(0.8)",
                      },
                    }}
                  />

                  <Box sx={styles.containerButtons}>
                    <Button
                      size="large"
                      variant="contained"
                      onClick={() => navigate(`/company/${company.id}/edit`)}
                      sx={{ fontSize: 12, paddingInline: 1, paddingBlock: 1 }}
                    >
                      Editar Empresa
                    </Button>
                  </Box>
                </Grid>
              )
          )}
        </Grid>
      </Box>
    </Box>
  );
};

const styles = {
  containerButtons: {
    display: "flex",
    gap: 1,
    mt: 1,
    justifyContent: "center",
  },
};
export default Company;
