import { makeGeneralRequest } from "./makeRequest";

export const createForm = ({ form, companyId }) =>
  makeGeneralRequest("POST", `companies/${companyId}/forms`, form);

export const fetchForms = ({ page = 1, companyId }) =>
  makeGeneralRequest("GET", `companies/${companyId}/forms?page=${page}`);

export const fetchFormsByFilters = ({ page = 1, companyId, data }) =>
  makeGeneralRequest("POST", `companies/${companyId}/forms/unified_search?page=${page}`, data);

export const fetchForm = ({ companyId, formId }) =>
  makeGeneralRequest("GET", `companies/${companyId}/forms/${formId}`);

export const updateForm = (form) => {
  const { companyId, formId, ...rest } = form;
  return makeGeneralRequest(
    "PUT",
    `companies/${companyId}/forms/${formId}`,
    rest
  );
};
