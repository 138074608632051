import * as React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "../views/main";
import Company from "../views/Company";
import AuthForm from "../views/Auth";
import PrivateRoutes from "./PrivateRoutes";
import { getCompanies } from "../services/company";
import Answer from "../views/AnswerForm/Answer";
import { ShowResponse, ResponseList, EditResponse } from "../views/Responses";
import { FormEditor, FormList } from "../views/Form";
import { CompanyEdit } from "../views/Company/CompanyEdit";
import { UserCreate, UserEdit, UsersList } from "../views/Users";
import Dashboards from "../views/Dashboards";
import CreateDash from "../views/Dashboards/CreateDash";
import EditDash from "../views/Dashboards/EditDash";
import { fetchDataWithLocalStorage } from "../utils";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthForm />,
  },
  {
    element: <PrivateRoutes />,
    children: [
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "company",
        children: [
          {
            index: true,
            element: <Company />,
            loader: () => fetchDataWithLocalStorage("companies", getCompanies),
          },
          {
            path: ":companyId",
            children: [
              {
                path: "edit",
                element: <CompanyEdit />,
              },
              {
                path: "form/:formId?",
                element: <FormEditor />,
              },
              {
                path: "responses/:formId?",
                element: <ResponseList />,
              },
              {
                path: "answerForm/:formId?",
                element: <Answer />,
              },
              {
                path: "form/:formId/editResponse/:responseId",
                element: <EditResponse />,
              },
              {
                path: "form/:formId/showResponse/:responseId",
                element: <ShowResponse />,
              },
            ],
          },
        ],
      },
      {
        path: "forms",
        element: <FormList />,
        loader: () => fetchDataWithLocalStorage("companies", getCompanies),
      },
      {
        path: "dashboards",
        element: <Dashboards />,
        loader: () => fetchDataWithLocalStorage("companies", getCompanies),
      },
      {
        path: "dashboards/new",
        element: <CreateDash />,
      },
      {
        path: "dashboards/edit/:dashboardId",
        element: <EditDash />,
      },
      {
        path: "users",
        element: <UsersList />,
      },
      {
        path: "users/new",
        element: <UserCreate />,
      },
      {
        path: "users/:userId/edit",
        element: <UserEdit />,
      },
    ],
  },
]);

const AppRoutes = () => {
  return <RouterProvider router={router} />;
};

export default AppRoutes;
