import { makeGeneralRequest } from "./makeRequest";

export const createResponse = ({ formSubmission, companyId, formId }) => {
  return makeGeneralRequest(
    "POST",
    `companies/${companyId}/forms/${formId}/form_submissions`,
    formSubmission
  );
};

export const getResponses = async ({ companyId, formId }) => {
  const response = await makeGeneralRequest(
    "GET",
    `companies/${companyId}/forms/${formId}/form_submissions`
  );
  return response;
};

export const updateResponse = ({
  contentUpdates,
  companyId,
  formId,
  responseId,
}) =>
  makeGeneralRequest(
    "PATCH",
    `companies/${companyId}/forms/${formId}/form_submissions/${responseId}`,
    contentUpdates
  );

export const getResponseById = ({ companyId, formId, responseId }) => {
  return makeGeneralRequest(
    "GET",
    `companies/${companyId}/forms/${formId}/form_submissions/${responseId}`
  );
};
