import React, { useEffect, useState } from "react";
import { useLoading } from "../../contexts/LoadingProvider";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import { Link, useLoaderData } from "react-router-dom";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Text,
  Paper,
  Button,
} from "../../components/core";
import { BodyContainer } from "../../components/layout";
import { getSectorsByCompany } from "../../services/sector";
import { getDashboards } from "../../services/dashboards";

export default function Dashboards() {
  const { openSnackbar } = useSnackbar();
  const { setLoading } = useLoading();
  const { companies } = useLoaderData();

  const [sectors, setSectors] = useState([]);
  const [dashboards, setDashboards] = useState([]);

  const savedCompanyIds = parseInt(localStorage.getItem("company_ids"));
  const savedSectorIds = parseInt(localStorage.getItem("sector_ids"));

  const [companySelected, setCompanySelected] = useState([savedCompanyIds]);
  const [sectorSelected, setSectorSelected] = useState([savedSectorIds]);

  const handleChange = ({ target }) => {
    const { value, name } = target;
    if (name === "company_ids") {
      setCompanySelected(value);
    } else {
      setSectorSelected(value);
    }
  };

  useEffect(() => {
    const fetchSectors = async () => {
      try {
        const selectedCompanyIds = companySelected;
        if (selectedCompanyIds !== null) {
          const response = await getSectorsByCompany({ selectedCompanyIds });
          setSectors(response.companies_sectors);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchSectors();
  }, [companySelected]);

  const getDashboardsData = async () => {
    try {
      setLoading(true);
      const formData = {
        company_ids: companySelected,
        sector_ids: sectorSelected,
      };
      const response = await getDashboards({ formData });
      setDashboards(response.dashboards);
    } catch (error) {
      openSnackbar("Ocorreu um erro", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDashboardsData();
  }, []);

  const handleSubmit = async () => {
    localStorage.setItem("company_ids", companySelected);
    localStorage.setItem("sector_ids", sectorSelected);
    setLoading(true);
    getDashboardsData();
  };

  useEffect(() => {
    getDashboardsData();
  }, []);

  return (
    <BodyContainer>
      <Box sx={{ display: "flex", alignItems: "center", my: 4 }}>
        <Text variant="h4" sx={{ fontWeight: "bold", width: "100%" }}>
          Dashboards
        </Text>
        <Button
          component={Link}
          variant="contained"
          to="/dashboards/new"
          sx={{ minWidth: 200 }}
        >
          Enviar nova dash
        </Button>
      </Box>
      <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
        <Paper elevation={2} sx={styles.formContainer}>
          <FormControl>
            <InputLabel>Selecione a empresa</InputLabel>
            <Select
              sx={{ minWidth: 200 }}
              value={companySelected}
              label="Selecione a empresa"
              onChange={handleChange}
              name="company_ids"
            >
              {companies && companies.length > 0 ? (
                companies.map((company) => (
                  <MenuItem key={company.id} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>Nenhuma empresa encontrada</MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel>Selecione o setor</InputLabel>
            <Select
              sx={{ minWidth: 200 }}
              value={sectorSelected}
              label="Selecione o setor"
              onChange={handleChange}
              name="sector_ids"
            >
              {sectors && sectors.length > 0 ? (
                sectors.map((item) =>
                  item.sectors.map((sector) => (
                    <MenuItem key={sector.id} value={sector.id}>
                      {sector.name}
                    </MenuItem>
                  ))
                )
              ) : (
                <MenuItem disabled>Nenhum setor disponível</MenuItem>
              )}
            </Select>
          </FormControl>
          <Button sx={styles.button} onClick={handleSubmit}>
            Pesquisar
          </Button>
        </Paper>
      </Box>

      {dashboards && dashboards.length > 0 ? (
        dashboards.map((dashboard) => (
          <Box
            sx={{
              marginInline: 0,
              marginBlock: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: 2,
              border: "1px solid #e0e0e0",
              borderRadius: 2,
              padding: 2,
            }}
          >
            <Text sx={{ margin: 2, fontSize: 26, fontWeight: 500 }}>
              {dashboard.name}
            </Text>
            <iframe
              title={dashboard.name}
              width="1140"
              height="541.25"
              src={dashboard.url}
              frameborder="0"
              allowFullScreen="true"
            ></iframe>
            <Button sx={styles.button} href={`dashboards/edit/${dashboard.id}`}>
              Editar formulário
            </Button>
          </Box>
        ))
      ) : (
        <Paper elevation={2} sx={{ padding: 2 }}>
          <Text variant="h3" sx={{ textTransform: "uppercase" }}>
            Ops, parece que não há nenhum dashboard cadastrado :(
          </Text>
        </Paper>
      )}
    </BodyContainer>
  );
}
const styles = {
  TableHead: {
    fontWeight: "bold",
    fontSize: "0.9rem",
  },
  TableRow: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.02)",
    },
    height: "70px",
    cursor: "pointer",
  },
  chipStyle: {
    width: "100px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "16px",
    fontSize: "0.875rem",
    margin: "auto",
  },
  button: {
    background: "#0e4292",
    color: "#fff",
    paddingInline: 4,
    "&:hover": {
      background: "#0e4294",
    },
  },
  formContainer: { display: "inline-flex", gap: 2, padding: 2 },
  TableHead: {
    fontWeight: "bold",
    fontSize: "0.9rem",
  },
  TableRow: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.02)",
    },
    height: "70px",
    cursor: "pointer",
  },
  chipStyle: {
    width: "100px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "16px",
    fontSize: "0.875rem",
    margin: "auto",
  },
  button: {
    background: "#0e4292",
    color: "#fff",
    paddingInline: 4,
    "&:hover": {
      background: "#0e4294",
    },
  },
  formContainer: { display: "inline-flex", gap: 2, padding: 2 },
  TableHead: {
    fontWeight: "bold",
    fontSize: "0.9rem",
  },
  TableRow: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.02)",
    },
    height: "70px",
    cursor: "pointer",
  },
  chipStyle: {
    width: "100px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "16px",
    fontSize: "0.875rem",
    margin: "auto",
  },
  button: {
    background: "#0e4292",
    color: "#fff",
    paddingInline: 4,
    "&:hover": {
      background: "#0e4294",
    },
  },
  formContainer: { display: "inline-flex", gap: 2, padding: 2 },
};

const roleColors = {
  gold: { background: "#fff5cc", color: "#8d7000" },
  silver: { background: "#e0e0e0", color: "#000000" },
  operator: { background: "#e6f7ff", color: "#005691" },
  gold: { background: "#fff5cc", color: "#8d7000" },
  silver: { background: "#e0e0e0", color: "#000000" },
  operator: { background: "#e6f7ff", color: "#005691" },
  gold: { background: "#fff5cc", color: "#8d7000" },
  silver: { background: "#e0e0e0", color: "#000000" },
  operator: { background: "#e6f7ff", color: "#005691" },
};
