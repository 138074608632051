import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Link, useLoaderData } from "react-router-dom";
import { useLoading } from "../../contexts/LoadingProvider";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import {
  Box,
  Text,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Switch,
  Tooltip,
  Chip,
  Stack,
} from "../../components/core";
import { BodyContainer } from "../../components/layout";
import { getCompanies } from "../../services/company";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ReplyIcon from "@mui/icons-material/Reply";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { formatDate, normalizeString } from "../../utils";
import { InputAdornment } from "@mui/material";
import useFetchForms from "../../hooks/useFetchForms";

const initialUnifiedSearch = {
  keyword: '',
  level_access: [],
  sector_ids: [],
  tag_ids: [],
}

export const FormList = () => {
  const { user } = useContext(AuthContext)

  const data = useLoaderData();

  const { openSnackbar } = useSnackbar();
  const { setLoading } = useLoading();

  const companySelectedFromLocalStorage = localStorage.getItem("companySelected");
  
  const [page, setPage] = useState(0);
  const [companySelected, setCompanySelected] = useState(companySelectedFromLocalStorage || data.companies[0].id);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [companies, setCompanies] = useState(data.companies);
  const [unifiedSearch, setUnifiedSearch] = useState(initialUnifiedSearch)
  
  const { data: forms, handleSearchForm, handleSwitchChange, refetchData } = useFetchForms(page , companySelected);

  useEffect(() => {
    if (data) return;
    const fetchData = async () => {
      try {
        setLoading(true);
        if (user.role === "gold") {
          const response = await getCompanies();
          setCompanies(response?.companies);
        } else if (user.role === "silver" || user.role === "operator") {
          setCompanies(user.companies);
        }
      } catch (error) {
        openSnackbar("Erro ao carregar as empresas", "error");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);


  const handleCompanyChange = ({ target }) => {
    setCompanySelected(localStorage.getItem("companySelected"));
    localStorage.setItem("companySelected", target.value);
    setCompanySelected(target.value);
    setPage(0);
  };

  return (
    <>
      {/* <BodyContainer>
        </Box>
            <Text sx={{ marginInline: 4 }}>Nenhum formulário encontrado</Text>
          )}
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChange}
            />
          </Box>
        </Box>
      </BodyContainer> */}
      <BodyContainer>
        <Box sx={{ display: "flex", alignItems: "center", my: 4 }}>
          <Text variant="h4" sx={{ fontWeight: "bold", width: "100%" }}>
            Formulários
          </Text>
          {user.role === "gold" && (
            <Button
              component={Link}
              variant="contained"
              to={`/company/${companySelected}/form`}
              sx={{ minWidth: 200 }}
            >
              Novo Formulário
            </Button>
          )}
        </Box>
        <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
          <Paper
            elevation={2}
            sx={{ flexDirection: "row", display: "flex", gap: 2, padding: 2 }}
          >
            <FormControl>
              <InputLabel>Selecione a empresa</InputLabel>
              <Select
                sx={{ minWidth: 200 }}
                size="small"
                value={companySelected}
                label="Selecione a empresa"
                onChange={handleCompanyChange}
              >
                {companies?.map((company) => (
                  <MenuItem key={company.id} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              sx={{ flexGrow: 1 }}
              size="small"
              label="Pesquisar formulário"
              variant="outlined"
              value={unifiedSearch.keyword}
              onChange={(e) => setUnifiedSearch({ keyword: normalizeString(e.target.value) })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {!!unifiedSearch.keyword &&
                      <IconButton
                        aria-label="remove"
                        onClick={() => setUnifiedSearch({ ...unifiedSearch, keyword: '' })}>
                        <CloseIcon />
                      </IconButton>
                    }
                  </InputAdornment>
                )
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSearchForm(unifiedSearch);
                }
              }}
            />
          </Paper>
          <Paper elevation={2}>
            <TablePagination
              component="div"
              count={forms?.length || 0}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value));
                setPage(0);
              }}
              labelRowsPerPage="Formulários por página:"
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
            />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={styles.TableHead}>Título</TableCell>
                  <TableCell sx={styles.TableHead}>Tags</TableCell>
                  {user.role === "gold" && (
                    <TableCell align="center" sx={styles.TableHead}>
                      Status
                    </TableCell>
                  )}
                  <TableCell align="center" sx={styles.TableHead}>
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {forms?.length > 0 ? (
                  forms
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((form, index) => (
                      <TableRow key={index} sx={styles.TableRow}>
                        <TableCell>
                          <Box
                            sx={{
                              maxWidth: "500px",
                              cursor: "default",
                            }}
                          >
                            {form.title.length > 50 ? (
                              <Tooltip
                                title={form.title}
                                placement="bottom-start"
                              >
                                <Text noWrap sx={{ fontWeight: 600 }}>
                                  {form.title}
                                </Text>
                              </Tooltip>
                            ) : (
                              <Text sx={{ fontWeight: 600 }} noWrap>
                                {form.title}
                              </Text>
                            )}
                            <Text
                              variant="caption"
                              sx={{ display: "block", color: "grey" }}
                            >
                              Criado em: {formatDate(form.created_at)}
                            </Text>
                          </Box>
                        </TableCell>

                        <TableCell>
                          {form.tags.length === 0 ? (
                            <Text variant="body2" color="textSecondary">
                              Sem tags
                            </Text>
                          ) : (
                            <Stack direction="row" spacing={1} flexWrap="wrap">
                              {form.tags.slice(0, 3).map((tag, index) => (
                                <Chip
                                  key={index}
                                  label={`#${tag.name}`}
                                  size="small"
                                  variant="outlined"
                                />
                              ))}
                              {form.tags.length > 3 && (
                                <Tooltip
                                  title={form.tags
                                    .map((tag) => `#${tag.name}`)
                                    .join(", ")}
                                >
                                  <Chip
                                    label={`+${form.tags.length - 3} tags`}
                                    size="small"
                                    variant="outlined"
                                  />
                                </Tooltip>
                              )}
                            </Stack>
                          )}
                        </TableCell>
                        {user.role === "gold" && (
                          <TableCell align="center">
                            <Tooltip
                              title={
                                form.active
                                  ? "Clique para desativar"
                                  : "Clique para ativar"
                              }
                            >
                              <Switch
                                checked={form.active}
                                onChange={() => handleSwitchChange(form)}
                                sx={{
                                  width: 34,
                                  height: 20,
                                  padding: 0,
                                  "& .MuiSwitch-switchBase": {
                                    padding: 0,
                                    "&.Mui-checked": {
                                      transform: "translateX(14px)",
                                      color: "#fff",
                                      "& + .MuiSwitch-track": {
                                        backgroundColor: "#0e4292",
                                        opacity: 1,
                                        border: "none",
                                      },
                                    },
                                    "&.Mui-focusVisible .MuiSwitch-thumb": {
                                      color: "#0e4292",
                                      border: "6px solid #fff",
                                    },
                                  },
                                  "& .MuiSwitch-thumb": {
                                    boxShadow: "none",
                                    width: 20,
                                    height: 20,
                                    margin: 0,
                                    backgroundColor: "#fff",
                                    border: "1px solid #E9E9EA",
                                  },
                                  "& .MuiSwitch-track": {
                                    borderRadius: 20 / 2,
                                    opacity: 1,
                                    backgroundColor: "#E9E9EA",
                                    boxSizing: "border-box",
                                  },
                                }}
                              />
                            </Tooltip>
                          </TableCell>
                        )}
                        <TableCell align="center">
                          <Tooltip title="Responder">
                            <IconButton
                              sx={styles.Actions}
                              component={Link}
                              to={`/company/${companySelected}/answerForm/${form.id}`}
                            >
                              <ReplyIcon />
                            </IconButton>
                          </Tooltip>

                          {user.role === "gold" && (
                            <Tooltip title="Editar">
                              <IconButton
                                sx={styles.Actions}
                                component={Link}
                                to={`/company/${companySelected}/form/${form.id}`}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          )}

                          <Tooltip title="Visualizar Respostas">
                            <IconButton
                              sx={styles.Actions}
                              component={Link}
                              to={`/company/${companySelected}/responses/${form.id}`}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Box sx={{ textAlign: "center", py: 4 }}>
                        <Text variant="subtitle1" color="textSecondary">
                          Nenhum formulário encontrado para a empresa
                          selecionada.
                        </Text>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={forms?.length || 0}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value));
                setPage(0);
              }}
              labelRowsPerPage="Formulários por página:"
              rowsPerPageOptions={[10, 15, 20]}
            />
          </Paper>
        </Box>
      </BodyContainer>
    </>
  );
};

const styles = {
  TableHead: {
    fontWeight: "bold",
    fontSize: "0.9rem",
  },
  TableRow: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.02)",
    },
    height: "70px",
  },
  Actions: {
    "&:hover": {
      color: "#0e4292",
    },
  },
  SwitchBox: {
    display: "flex",
    alignItems: "center",
  },
};
