import { makeAuthRequest } from "./makeRequest";

export const loginRequest = async (user) =>
  makeAuthRequest("POST", "/users/sign_in", { user });

export const recoverUserByToken = async (token) => {
  const config = { headers: { Authorization: token } };
  return makeAuthRequest("GET", "/member-data", null, config);
};

export const registerRequest = async ({ user }) =>
  makeAuthRequest("POST", "/users", { user });

export const logoutRequest = async (authToken) => {
  const config = { headers: { Authorization: authToken } };
  return makeAuthRequest("DELETE", "/users/sign_out", null, config);
};

export const refreshTokenRequest = async (refreshToken) => {
  const config = { headers: { Authorization: `Bearer ${refreshToken}` } };
  return makeAuthRequest(
    "POST",
    "/users/tokens/refresh",
    { refreshToken },
    config
  );
};
