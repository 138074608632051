import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BodyContainer } from "../../components/layout";
import { Text, Button, Box } from "../../components/core";
import { updateResponse } from "../../services/answer";
import { fetchFields } from "../../services/field";
import { useLoading } from "../../contexts/LoadingProvider";
import { useSnackbar } from "../../contexts/SnackbarProvider";

import { useNavigate } from "react-router-dom";
import { RenderField } from "../../components/patterns/RenderField";

export const EditResponse = () => {
	const { formId, companyId, responseId } = useParams();
	const { openSnackbar } = useSnackbar();
	const { setLoading } = useLoading();
	const navigate = useNavigate();
	const [fields, setFields] = useState([]);
	const [answers, setAnswers] = useState({});

	useEffect(() => {
		fetchFields({ companyId, formId }).then((res) => setFields(res.fields));
	}, [companyId, responseId, formId]);

	const handleChange = (fieldLabel, fieldTitle, value) => {
		const key = fieldLabel || fieldTitle;
		setAnswers({ ...answers, [key]: value });
	};

	const handleSubmit = async () => {
		setLoading(true);
		const contentUpdates = {
			form_submission: { content: answers },
		};
		try {
			await updateResponse({ contentUpdates, companyId, formId, responseId });
			openSnackbar("Resposta editada com sucesso!");
			navigate(`/company/${companyId}/responses/${formId}`);
		} catch (error) {
			openSnackbar("Erro ao editar a resposta.", "error");
		} finally {
			setLoading(false);
		}
	};

	return (
		<BodyContainer>
			<Text
				variant="h4"
				sx={{ marginBlock: 2, marginInline: 4, fontWeight: "bold" }}
			>
				Responder formulário
			</Text>
			<Box sx={styles.container}>
				{fields.map((field) => (
					<Box key={field.id} sx={{ width: "100%" }}>
						<RenderField field={field} handleChange={handleChange} />
					</Box>
				))}
				<Button onClick={handleSubmit} sx={styles.button}>
					Salvar Alterações
				</Button>
			</Box>
		</BodyContainer>
	);
};

const styles = {
	button: {
		marginBlock: 1,
		background: "#0e4292",
		color: "#FFF",
		fontWeight: 600,
		maxWidth: "200px",
	},
	input: {
		width: 400,
	},
	container: {
		display: "flex",
		flexDirection: "column",
		gap: 1,
		width: "100%",
		marginBlock: 2,
		marginInline: 4,
	},
};
