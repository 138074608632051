import { useState } from "react";

export const useFieldManager = (initialContent) => {
  const [formContent, setFormContent] = useState(initialContent);
  const [deletedFields, setDeletedFields] = useState([]);

  const editField = (index, newTitle) => {
    setFormContent((prevContent) =>
      prevContent.map((field, idx) =>
        idx === index ? { ...field, title: newTitle, label: newTitle } : field
      )
    );
  };

  const editFieldType = (index, newType) => {
    setFormContent((prevContent) =>
      prevContent.map((field, idx) =>
        idx === index ? { ...field, field_type: newType } : field
      )
    );
  };

  const addFieldOption = (index, optionInput) => {
    setFormContent((prevContent) =>
      prevContent.map((field, idx) =>
        idx === index
          ? {
              ...field,
              options: [...field.options, optionInput],
              optionInput: "",
            }
          : field
      )
    );
  };

  const deleteField = (index) => {
    const fieldToDelete = formContent[index];
    if (fieldToDelete.id) {
      setDeletedFields([...deletedFields, fieldToDelete.id]);
    }
    setFormContent((prevContent) =>
      prevContent.filter((_, idx) => idx !== index)
    );
  };

  const setTextField = (index, value) => {
    setFormContent((prevContent) =>
      prevContent.map((field, idx) =>
        idx === index ? { ...field, optionInput: value } : field
      )
    );
  };

  const moveField = (fieldIndex, direction) => {
    const formFields = [...formContent];
    const newIndex = direction === "up" ? fieldIndex - 1 : fieldIndex + 1;
    const temp = formFields[fieldIndex];
    formFields[fieldIndex] = formFields[newIndex];
    formFields[newIndex] = temp;
    setFormContent(formFields);
  };

  return {
    formContent,
    deletedFields,
    setFormContent,
    editField,
    editFieldType,
    moveField,
    addFieldOption,
    setTextField,
    deleteField,
  };
};
