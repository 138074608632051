import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Text,
  OutlinedInput,
  Checkbox,
  ListItemText,
  FormControlLabel,
  TextField,
  Button,
} from "../../components/core";
import { BodyContainer } from "../../components/layout";
import { getCompanies } from "../../services/company";
import { useLoading } from "../../contexts/LoadingProvider";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import { getSectorsByCompany } from "../../services/sector";
import { updateDash, getDashBoardById } from "../../services/dashboards";
import { useNavigate, useParams } from "react-router-dom";

export default function EditDash() {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();
  const { setLoading } = useLoading();
  const [companySelected, setCompanySelected] = useState([]);
  const [sectorSelected, setSectorSelected] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [url, setUrl] = useState("");
  const [name, setName] = useState("");
  const { dashboardId } = useParams();
  const [dashboard, setDashboard] = useState([]);
  const [initialCompanyName, setInitialCompanyName] = useState("");
  const [initialSectorName, setInitialSectorName] = useState("");
  const [initialDashboardValues, setInitialDashboardValues] = useState({
    name: "",
    url: "",
  });
  useEffect(() => {
    async function initializeData() {
      try {
        setLoading(true);
        const companiesResponse = await getCompanies();
        setCompanies(companiesResponse.companies);
      } catch (error) {
        openSnackbar("Erro ao carregar dados", "error");
      } finally {
        setLoading(false);
      }
    }

    initializeData();
  }, []);

  useEffect(() => {
    const fetchSectors = async () => {
      try {
        const selectedCompanyIds = companySelected.map((company) => company.id);
        if (selectedCompanyIds.length) {
          const response = await getSectorsByCompany({ selectedCompanyIds });
          setSectors(response.companies_sectors);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchSectors();
  }, [companySelected]);

  const handleSectorChange = (event) => {
    setSectorSelected(event.target.value);
    setFormData({
      ...formData,
      sector_ids: event.target.value.map((sector) => sector.id),
    });
  };

  const handleChangeCompanies = (event) => {
    console.log(event.target.value);
    setCompanySelected(event.target.value);
    setFormData({
      ...formData,
      company_ids: event.target.value.map((company) => company.id),
    });
  };

  const handleTextFieldChangeName = (event) => {
    setFormData({
      ...formData,
      name: event.target.value,
    });
  };
  const handleTextFieldChangeLink = (event) => {
    setFormData({
      ...formData,
      url: event.target.value,
    });
  };
  useEffect(() => {
    const fetchDashboards = async () => {
      try {
        const response = await getDashBoardById({ dashboardId });
        setDashboard(response.dashboard);

        if (response.dashboard) {
          setCompanySelected(response.dashboard.companies);
          setSectorSelected(response.dashboard.sectors);

          setInitialDashboardValues({
            ...formData,
            name: response.dashboard.name,
            url: response.dashboard.url,
          });

          setFormData({
            name: response.dashboard.name,
            url: response.dashboard.url,
            company_ids: response.dashboard.companies.map((c) => c.id),
            sector_ids: response.dashboard.sectors.map((s) => s.id),
          });
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchDashboards();
  }, [dashboardId]);

  const [formData, setFormData] = useState({
    name: name,
    url: url,
    company_ids: [],
    sector_ids: [],
  });

  const handleSubmit = async () => {
    try {
      console.log(formData);
      await updateDash({ formData, dashboardId });
      openSnackbar("Resposta enviada com sucesso!");
      navigate(`/dashboards`);
    } catch (error) {
      openSnackbar("Erro ao enviar a resposta.", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <BodyContainer>
      <Box sx={{ mt: 4 }}>
        <Text variant="h1">Editar dashboard</Text>
      </Box>
      <Box
        sx={{
          marginBlock: 3,
          gap: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextField
          label="Nome do dashboard"
          variant="outlined"
          value={formData.name || initialDashboardValues.name}
          onChange={handleTextFieldChangeName}
          required
        />
        <FormControl variant="filled" sx={{ m: 0, minWidth: 120 }}>
          <InputLabel variant="filled" sx={{ fontSize: 12 }} required>
            Selecione as empresas
          </InputLabel>
          <Select
            labelId="empresas-checkbox-label"
            multiple
            value={companySelected}
            onChange={handleChangeCompanies}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => {
              return selected.map((company) => company.name).join(", ");
            }}
            MenuProps={MenuProps}
          >
            {companies.map((company) => (
              <MenuItem key={company.id} value={company}>
                <Checkbox
                  checked={companySelected.some(
                    (selectedCompany) => selectedCompany.id === company.id
                  )}
                />
                <ListItemText primary={company.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel variant="filled" sx={{ fontSize: 12 }} required>
            Selecione os setores
          </InputLabel>
          <Select
            fullWidth
            multiple
            value={sectorSelected}
            onChange={handleSectorChange}
            input={<OutlinedInput label="Setor" />}
            MenuProps={MenuProps}
            renderValue={(selected) => {
              return selected.map((sector) => sector.name).join(", ");
            }}
          >
            {sectors &&
              sectors.map((item) =>
                item.sectors.map((sector) => {
                  return (
                    <MenuItem key={sector.id} value={sector}>
                      <Checkbox
                        checked={sectorSelected.some(
                          (selectedSector) => selectedSector.id === sector.id
                        )}
                      />
                      <ListItemText primary={sector.name} />
                    </MenuItem>
                  );
                })
              )}
          </Select>
        </FormControl>
        <TextField
          label="Insira o link do formulário"
          variant="outlined"
          value={formData.url || initialDashboardValues.url}
          onChange={handleTextFieldChangeLink}
        />

        <Box sx={styles.containerButtons}>
          <Button onClick={handleSubmit} sx={styles.button}>
            Editar dashboard
          </Button>
          <Button
            onClick={() => navigate("/dashboards")}
            sx={styles.buttonBack}
          >
            Cancelar
          </Button>
        </Box>
      </Box>
    </BodyContainer>
  );
}

const styles = {
  button: {
    marginBlock: 1,
    background: "#0e4292",
    color: "#FFF",
    fontWeight: 600,
    maxWidth: "200px",
    paddinInline: 2,
  },
  buttonBack: {
    marginBlock: 1,
    background: "#DEDEDE",
    color: "#AAA",
    maxWidth: "200px",
    fontWeight: 600,
    paddinInline: 2,
  },
  containerButtons: {
    display: "flex",
    gap: 2,
  },
};
