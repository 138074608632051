import React, { useContext, useEffect, useRef } from "react";
import { TextField, Button, Card, Text, Box } from "../../components/core";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import { useLoading } from "../../contexts/LoadingProvider";

function AuthForm() {
  const { openSnackbar } = useSnackbar();
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const { loginUser } = useAuth();
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  // useEffect(() => {
  //   const token = localStorage.getItem("authentication_token");
  //   if (user && token) {
  //     openSnackbar("Você já está logado", "success");
  //     navigate('/company');
  //   }
  // }, [user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const user = { email, password };

    setLoading(true);

    const response = await loginUser(user);

    if (response.name === "AxiosError") {
      openSnackbar(response.response.data.message, "error");
    } else {
      navigate("/forms");
      openSnackbar(response.data.message);
    }
    setLoading(false);
  };

  return (
    <Box
      sx={{
        background:
          "radial-gradient(50% 50% at 50% 50%, #133463 0%, #001D3D 100%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: 2,
          boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "150px 10px",
          height: 400,
          width: 400,
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: 200,
            height: 100,
            left: "28%",
            top: "-5%",
            zIndex: 9,
            right: "0%",
            background: "#F6C543",
            borderRadius: "0px 10px 0px 50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text variant="h5" component="h1" sx={{ fontWeight: 700 }}>
            ATALAIA
          </Text>
        </Box>
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 14,
          }}
        >
          <TextField
            label="Email"
            variant="filled"
            inputRef={emailRef}
            sx={{ width: 300 }}
            InputProps={{ disableUnderline: true }}
          />
          <TextField
            label="Senha"
            variant="filled"
            type="password"
            inputRef={passwordRef}
            margin="normal"
            InputProps={{ disableUnderline: true }}
            sx={{ width: 300 }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ marginTop: 2, paddingBlock: 1 }}
          >
            LOGIN
          </Button>
        </form>
      </Card>
    </Box>
  );
}

export default AuthForm;
