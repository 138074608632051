import { useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import { Layout } from "../components/layout/";
import { lastIndexArray } from "../utils/constant";

const PrivateRoutes = () => {
  const { user, loginUserWithToken } = useAuth();
  const location = useLocation();

  const token = localStorage.getItem("auth_token");

  useEffect(() => {
    if (token) {
      loginUserWithToken(token).catch((error) => {
        console.error(error);
      });
    }
  }, [location, loginUserWithToken, token]);

  const isUserAllowed = (path) => {
    if (user && (user.role === 'silver' || user.role === 'operator')) {
      const restrictedRoutes = [
        "/dashboards/new",
      ];

      if (restrictedRoutes.includes(path)) {
        return false;
      }
    }

    return true;
  };

  const splittedPathName = location.pathname.split('/').slice(0, -1);
  const lastIndexPathName = lastIndexArray(splittedPathName)


  return (
    <>
      {isUserAllowed(location.pathname) ? (
        <Layout>
          <Outlet />
        </Layout>
      ) : (
        <Navigate to={`/${splittedPathName[lastIndexPathName]}`} />
      )}
    </>
  );
};

export default PrivateRoutes;
