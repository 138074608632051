import { api, apiUsers, apiV1 } from "./api";

const makeRequest =
  (apiInstance) =>
  async (method, endpoint, data = null, options = {}) => {
    try {
      let response;
      switch (method) {
        case "GET":
          response = await apiInstance.get(endpoint, options);
          break;
        case "POST":
          response = await apiInstance.post(endpoint, data);
          break;
        case "PUT":
          response = await apiInstance.put(endpoint, data);
          break;
        case "PATCH":
          response = await apiInstance.patch(endpoint, data, options);
          break;
        case "DELETE":
          response = await apiInstance.delete(endpoint);
          break;
        default:
          throw new Error(`Invalid HTTP method: ${method}`);
      }
      return apiInstance === api ? response : response.data;
    } catch (error) {
      return error;
    }
  };

export const makeAuthRequest = makeRequest(api);
export const makeGeneralRequest = makeRequest(apiV1);
export const makeUsersRequest = makeRequest(apiUsers);
