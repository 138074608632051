import S3FileUpload from "react-s3";

const config = {
  bucketName: process.env.REACT_APP_AWS_S3_ALIAS,
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
};

export const uploadFileToS3 = (file) => {
  return S3FileUpload.uploadFile(file, config)
    .then((data) => data)
    .catch((err) => {
      throw err;
    });
};
