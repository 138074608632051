import { makeGeneralRequest } from "./makeRequest";
export const createDashboard = ({ formData }) => {
  return makeGeneralRequest("POST", `/dashboards`, formData);
};

export const getDashboards = ({ formData }) => {
  return makeGeneralRequest("POST", `/dashboards/search`, formData);
};

export const updateDash = ({ formData, dashboardId }) => {
  return makeGeneralRequest("PATCH", `/dashboards/${dashboardId}`, formData);
};

export const getDashBoardById = ({ dashboardId }) => {
  return makeGeneralRequest("GET", `/dashboards/${dashboardId}`);
};
