import React, { createContext, useState, useEffect, useContext } from "react";
import {
  loginRequest,
  logoutRequest,
  recoverUserByToken,
  registerRequest,
} from "../services/authentication";
import axios from "axios";

export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

const initialState = {
  id: null,
  username: null,
  email: null,
  role: null,
  company_id: null,
  sector_id: null,
  name: null,
  last_name: null,
}


export function AuthProvider({ children }) {
  const [authToken, setAuthToken] = useState(null);

  const userFromLocalStorage = JSON.parse(localStorage.getItem('user'))

  const [user, setUser] = useState(userFromLocalStorage || initialState);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!userFromLocalStorage) {
      logoutUser();
      setUser(initialState);
    }
  }, [userFromLocalStorage]);

  async function registerUser(payload) {
    try {
      const user = {
        email: payload.email,
        password: payload.password,
        password_confirmation: payload.password_confirmation,
      };
      const response = await registerRequest(user);
      setUserInfo(response);
      return response;
    } catch (error) {
      setError(error.message);
      throw error;
    }
  }

  async function loginUser(payload) {
    const body = {
      email: payload.email,
      password: payload.password,
    };
    const response = await loginRequest(body);

    if (response.name === "AxiosError") {
      setError(response.response.data.message);
    } else {
      setUserInfo(response);
    }

    return response;
  }

  async function logoutUser() {
    try {
      await logoutRequest(authToken);
      resetUserInfo();
    } catch (error) {
      setError(error.message);
      throw error;
    }
  }

  async function loginUserWithToken(token) {
    try {
      const response = await recoverUserByToken(token);
      setUserInfoFromToken(response, token);
      return response;
    } catch (error) {
      setError(error.message);
      throw error;
    }
  }

  function setUserInfo(data) {
    setUser(data.data.user);
    setAuthToken(data.headers.authorization);
    axios.defaults.headers.common["Authorization"] = data.headers.authorization;
    localStorage.setItem("authentication_token", data.headers.authorization);
    localStorage.setItem("user", JSON.stringify(data.data.user));
  }

  function setUserInfoFromToken(data, token) {
    setUser(data.data.user);
    setAuthToken(token);
    axios.defaults.headers.common["Authorization"] = token;
    localStorage.setItem("authentication_token", token);
    localStorage.setItem("user", JSON.stringify(data.data.user));
  }

  function resetUserInfo() {
    setUser({
      id: null,
      username: null,
      email: null,
    });
    setAuthToken(null);
    localStorage.removeItem("authentication_token");
    localStorage.removeItem("companies");
    localStorage.removeItem("companySelected");
    axios.defaults.headers.common["Authorization"] = null;
  }

  const value = {
    authToken,
    user,
    error,
    registerUser,
    loginUser,
    logoutUser,
    loginUserWithToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
