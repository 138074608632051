import { Box, FormGroup, TextField, Button } from "../core";
import { OptionItem } from "./OptionItem";

export const Radio = ({
  field,
  index,
  openEditModal,
  removeFieldOption,
  setTextField,
  addFieldOption,
}) => {
  return (
    <Box>
      <FormGroup>
        {field.options.map((item, optionIndex) => (
          <OptionItem
            key={`radioChoice_${optionIndex}`}
            fieldName={field.name}
            option={item}
            onEdit={() => openEditModal(index, optionIndex)}
            onRemove={() => removeFieldOption(index, optionIndex)}
            controlType="radio"
          />
        ))}
      </FormGroup>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 2,
          mt: 2
        }}
      >
        <TextField
          size="small"
          fullWidth
          value={field.optionInput}
          onChange={(e) => setTextField(index, e.target.value)}
          placeholder="Adicionar uma Opção"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => addFieldOption(index, field.optionInput)}
        >
          Adicionar
        </Button>
      </Box>
    </Box>
  );
};
