import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  CssBaseline,
  Text,
  Button,
} from "../core";
import BusinessIcon from "@mui/icons-material/Business";
import MenuIcon from "@mui/icons-material/Menu";
import ArticleIcon from "@mui/icons-material/Article";
import { People } from "@mui/icons-material";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
const drawerWidth = 240;

const MenuItems = ({ items, companyId = null }) => {
  return (
    <List>
      {items.map((item) => {
        const isDisabled = item.companyIdRequired && !companyId;
        return (
          <Link
            key={item.text}
            to={isDisabled ? "#" : item.route}
            style={{
              textDecoration: "none",
              textTransform: "none",
              color: isDisabled ? "gray" : "black",
              pointerEvents: isDisabled ? "none" : "auto",
            }}
          >
            <ListItemButton
              sx={{
                "&:hover": {
                  backgroundColor: isDisabled ? "initial" : "#f3f3f3",
                },
              }}
              disabled={isDisabled}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </Link>
        );
      })}
    </List>
  );
};

export const Menu = () => {
  const { companyId } = useParams();
  const [open, setOpen] = useState(false);
  const authToken = localStorage.getItem("authentication_token");
  const { logoutUser } = useAuth();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleLogoutClick = async () => {
    try {
      await logoutUser(authToken);
      navigate("/");
    } catch (error) {
      console.error("Erro ao fazer logout:", error);
    }
  };
  const items = [
    {
      text: "Empresas",
      icon: <BusinessIcon color="primary" />,
      route: "/company",
      companyIdRequired: false,
    },
    {
      text: "Formulários",
      icon: <ArticleIcon color="primary" />,
      route: `/forms`,
    },
    {
      text: "Dashboards",
      icon: <ArticleIcon color="primary" />,
      route: `/dashboards`,
      companyIdRequired: false,
    },
    {
      text: "Usuários",
      icon: <People color="primary" />,
      route: `/users`,
    },
  ];

  return (
    <Box>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar
          sx={{ display: "inline-flex", justifyContent: "space-between" }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: "flex", alignItems: "flex-end", gap: 1 }}>
            <img
              src="/grupo-unus.png"
              alt="logo"
              style={{ width: "100%", height: 18 }}
            />
            <img src="/ATALAIA.svg" alt="logo" />
          </Box>
          <Button
            onClick={handleLogoutClick}
            sx={{
              backgroundColor: "#f6c543",
              "&:hover": {
                backgroundColor: "#ffba00",
              },
            }}
          >
            sair
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <Box sx={{ paddingTop: 7 }}>
            <MenuItems items={items} companyId={companyId} />
          </Box>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          <Box sx={{ paddingTop: 8 }}>
            <MenuItems items={items} companyId={companyId} />
          </Box>
        </Drawer>
      </Box>
    </Box>
  );
};
