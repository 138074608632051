import { useEffect, useState, useRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { getCompany } from "../../services/company";
import { useParams } from "react-router";
import {
  Text,
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Button,
  IconButton,
  TableCell,
} from "../../components/core";
import { getSectors, createSector, updateSector } from "../../services/sector";
import { updateCompany } from "../../services/company";
import { useSnackbar } from "../../contexts/SnackbarProvider";
import { useLoading } from "../../contexts/LoadingProvider";
import ClearIcon from "@mui/icons-material/Clear";
import { deepEqual } from "../../utils/deepEqual";

export const CompanyEdit = () => {
  const initialCompanyRef = useRef({});

  const navigate = useNavigate();

  const { companyId } = useParams();

  const { openSnackbar } = useSnackbar();

  const { setLoading } = useLoading();

  const [company, setCompany] = useState({});
  const [sectors, setSectors] = useState([]);
  const [initialSectors, setInitialSectors] = useState([]);
  const [file, setFile] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const objectUrl = URL.createObjectURL(file);
    setFile(file);
    setCompany({ ...company, thumbnail_url: objectUrl });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const handleAxiosError = (response, errorMessage) => {
        if (response.name === "AxiosError") {
          navigate("/company");
          setLoading(false);
          openSnackbar(errorMessage, "error");
          return true;
        }
        return false;
      };

      const { company: companyResponse } = await getCompany({ companyId });

      if (
        handleAxiosError(
          companyResponse,
          "Oops! Ocorreu um erro ao carregar dados da compania."
        )
      ) {
        return;
      }

      setCompany(companyResponse);
      initialCompanyRef.current = companyResponse;

      const { sectors } = await getSectors({ companyId });
      if (
        handleAxiosError(
          sectors,
          "Oops! Ocorreu um erro ao carregar setores."
        )
      ) {
        return;
      }

      setSectors(sectors);
      setInitialSectors(sectors);
      setLoading(false);
    };

    fetchData();
  }, [companyId]);

  const handleChange = ({ target }) => {
    const { value } = target;
    setCompany({
      ...company,
      name: value,
    });
  };

  const handleEditSector =
    (index) =>
      ({ target }) => {
        const { value } = target;

        let updatedSectors = sectors.map((sector, idx) =>
          idx === index ? { ...sector, name: value } : sector
        );

        setSectors(updatedSectors);
      };

  const handleAddSector = () => {
    const newSector = {
      name: "Novo Setor",
    };
    setSectors([...sectors, newSector]);
  };

  // const handleRemoveSector = (indexToRemove) => {
  //   const updatedSectors = sectors.filter(
  //     (sector, index) => index !== indexToRemove
  //   );
  //   setSectors(updatedSectors);
  // };

  const handleSave = async () => {
    setLoading(true);

    if (file || company.name !== initialCompanyRef.current.name) {
      const formData = new FormData();

      if (file) formData.append("company[thumbnail]", file);
      if (company.name !== initialCompanyRef.current.name)
        formData.append("company[name]", company.name);

      const headers = {
        "Content-Type": "multipart/form-data",
      };

      const response = await updateCompany(companyId, formData, { headers });

      if (response?.name === "AxiosError") {
        setLoading(false);

        openSnackbar(
          `Ops! Ocorreu um erro ao tentar atualizar a Companhia.`,
          "error"
        );
        return;
      }
    }

    const sectorPromises = sectors.map((currentSector) => {
      if (currentSector.id) {
        const initialSector = initialSectors.find(
          (s) => s.id === currentSector.id
        );

        if (initialSector && initialSector.name !== currentSector.name) {
          return updateSector({
            companyId,
            sectorId: currentSector.id,
            name: currentSector.name,
          });
        }
        return Promise.resolve("Sector Unchanged");
      } else {
        return createSector({
          companyId,
          sectors: { sectors: [{ name: currentSector.name }] },
        });
      }
    });
    const sectorResults = await Promise.allSettled(sectorPromises);
    const errors = sectorResults.filter(
      (result) => result.value?.name === "AxiosError"
    );
    if (errors.length > 0) {
      openSnackbar(
        `Ops! Ocorreram erros ao tentar atualizar os setores.`,
        "error"
      );
    } else {
      openSnackbar("Dados atualizados com sucesso!", "success");
    }

    navigate("/company");
    setLoading(false);
  };

  const hasDataChanged = () => {
    return (
      !deepEqual(company, initialCompanyRef.current) ||
      !deepEqual(sectors, initialSectors)
    );
  };

  return (
    <Box sx={styles.containerForm}>
      <Text variant="h4" fontWeight="bold">
        {initialCompanyRef.current.name}
      </Text>
      <Box sx={{ gap: 10, display: "flex", flexDirection: "column" }}>
        <TextField onChange={handleChange} value={company.name} />
        <Box {...getRootProps()} style={styles.dropZone}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Solte os arquivos aqui...</p>
          ) : (
            <p>
              Arraste e solte o arquivo aqui, ou clique para selecionar o
              arquivo.
            </p>
          )}

          {company.thumbnail_url && (
            <img
              src={company.thumbnail_url}
              alt="Thumbnail Preview"
              style={styles.thumbnail}
            />
          )}
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Text sx={{ fontWeight: 600 }}>Setores</Text>
                </TableCell>
                <TableCell align="right">
                  <Button variant="contained" onClick={() => handleAddSector()}>
                    Novo Setor
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sectors.length > 0 &&
                sectors.map((sector, index) => (
                  <TableRow
                    key={sector.id + `idx${index}`}
                    sx={{
                      px: 4,
                      py: 1,
                    }}
                  >
                    <TableCell colSpan={2}>
                      <TextField
                        sx={{
                          minWidth: 300,
                          fontWeight: "600",
                          fontSize: "24px",
                          "& .MuiInput-underline:before": {
                            borderBottom: "none",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottom: "default",
                          },
                          "& .MuiInput-underline:hover:not(.Mui-disabled):before":
                          {
                            borderBottom: "none",
                          },
                        }}
                        value={sector.name}
                        variant="standard"
                        onChange={handleEditSector(index)}
                      />
                    </TableCell>
                    {/* <TableCell align="right">
                      <IconButton
                        aria-label="remover"
                        sx={{ "&:hover": { color: "red" } }}
                        onClick={() => handleRemoveSector(index)}
                      >
                        <ClearIcon />
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          disabled={!hasDataChanged()}
          onClick={handleSave}
        >
          Save
        </Button>
        <Button variant="contained" onClick={() => navigate("/company")}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  containerForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    gap: 2,
    px: 4,
    py: 4,
    maxWidth: 1000,
  },
  dropZone: {
    border: "2px dashed gray",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
  },
  thumbnail: {
    marginTop: "15px",
    maxWidth: "200px",
    maxHeight: "200px",
  },
};
