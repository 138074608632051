import { useState, useEffect, useRef } from 'react';
import { useSnackbar } from '../contexts/SnackbarProvider';
import { useLoading } from '../contexts/LoadingProvider';
import { fetchForms, fetchFormsByFilters, updateForm } from '../services/form';

const useFetchForms = (page, companyId) => {
  const [data, setData] = useState(null);
  const { openSnackbar } = useSnackbar();
  const { setLoading } = useLoading();
  const isMountedRef = useRef(true);


  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetchForms({ page, companyId });
      if (isMountedRef.current) {
        setData(response.forms);
      } else {
        return () => {
          isMountedRef.current = false;
        };
      }
    } catch (error) {
      handleFetchError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFetchError = (error) => {
    openSnackbar("Erro ao carregar os formulários", "error");
  };

  const handleSearchForm = async (data) => {
    try {
      setLoading(true);
      const response = await fetchFormsByFilters({ page, companyId, data  });
      setData(response?.forms);
    } catch (error) {
      handleFetchError(error);
    } finally {
      setLoading(false)
    }
  };

  const refetchData = async () => {
    fetchData();
  };


  const handleSwitchChange = async (form) => {
    const newActiveState = !form.active;
    try {
      const updatedData = data.map((f) => {
        if (f.id === form.id) {
          return { ...f, active: newActiveState };
        }
        return f;
      });
      setData(updatedData);

      await updateForm({
        formId: form.id,
        companyId,
        active: newActiveState,
      });
      openSnackbar(`Formulário ${newActiveState ? "ativado" : "desativado"} com sucesso!`, "success");
    } catch (error) {
      handleFetchError(error);
      const revertedData = data.map((f) => {
        if (f.id === form.id) {
          return { ...f, active: !newActiveState };
        }
        return f;
      });
      setData(revertedData);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, companyId]);

  return { data, handleSearchForm, handleSwitchChange, refetchData };
};

export default useFetchForms;
