import { TextField } from "../core";

export const Paragraph = () => {
  return (
    <TextField
      size="small"
      fullWidth
      placeholder="Resposta Longa"
      multiline
      disabled
      rows={4}
    />
  );
};
